import React, { useState } from "react";
import { Chart } from "chart.js";
// import PieChart from "../PieChart";
// import TemperatureChart from "../temperatureChart";
// import NavigationLoggedIn from "../../NavigationLoggedIn";
import { Link } from "react-router-dom";

const Dashboard = () => {
  const [sidenav, setSidenav] = useState(true);
  const temperatureData = [
    { time: "00:00", temperature: 22 },
    { time: "01:00", temperature: 21 },
    { time: "02:00", temperature: 20 },
    // Add more data points here
  ];
  
  return (
    <>
    <div id="view" className="h-full w-screen flex">
      <button
        onClick={() => setSidenav(!sidenav)}
        className="p-2 border-2 bg-white rounded-md border-gray-200 shadow-lg text-gray-500 focus:bg-green-500 focus:outline-none focus:text-white absolute top-0 left-0 sm:hidden"
      >
        <svg
          className="w-5 h-5 fill-current"
          fill="currentColor"
          viewBox="0 0 20 20"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z"
            clipRule="evenodd"
          ></path>
        </svg>
      </button>
      {/* Middle content area */}
      <div className="w-full">
        <div className="flex justify-around w-full p-4">
            {/* <div className="w-1/2 p-2"><PieChart /></div>
            <div className="w-1/2 p-2"><TemperatureChart data={temperatureData} /></div> */}
        </div>
      </div>
    </div>
    </>
  );
};

export default Dashboard;
