import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import API from "./api";

// Fetch all events
export const fetchEvents = createAsyncThunk("events/fetchEvents", async (_, { rejectWithValue }) => {
  try {
    const response = await API.get("/events");
    return response.data; // Array of events
  } catch (error) {
    return rejectWithValue(error.response?.data?.message || "Failed to fetch events.");
  }
});

// Fetch event by ID
export const fetchEventById = createAsyncThunk("events/fetchEventById", async (id, { rejectWithValue }) => {
  try {
    const response = await API.get(`/events/${id}`);
    return response.data; // Single event object
  } catch (error) {
    return rejectWithValue(error.response?.data?.message || "Failed to fetch event.");
  }
});

// Create an event
export const createEvent = createAsyncThunk(
    "events/createEvent",
    async (eventData, { rejectWithValue }) => {
      try {
        const response = await API.post("/events", eventData, {
          headers: { "Content-Type": "multipart/form-data" },
        });
        return response.data;
      } catch (error) {
        return rejectWithValue(error.response?.data?.message || "Failed to create event.");
      }
    }
  );

// Update an event
export const updateEvent = createAsyncThunk(
  "events/updateEvent",
  async ({ id, eventData }, { rejectWithValue }) => {
    try {
      const response = await API.put(`/events/${id}`, eventData, {
        headers: { "Content-Type": "multipart/form-data" },
      });
      return response.data; // Updated event object
    } catch (error) {
      return rejectWithValue(error.response?.data?.message || "Failed to update event.");
    }
  }
);

// Delete an event
export const deleteEvent = createAsyncThunk("events/deleteEvent", async (id, { rejectWithValue }) => {
  try {
    await API.delete(`/events/${id}`);
    return id; // Return the deleted event ID
  } catch (error) {
    return rejectWithValue(error.response?.data?.message || "Failed to delete event.");
  }
});

const eventSlice = createSlice({
  name: "events",
  initialState: {
    events: [], // List of events
    selectedEvent: null, // For single event operations
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      // Fetch all events
      .addCase(fetchEvents.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchEvents.fulfilled, (state, action) => {
        state.loading = false;
        state.events = action.payload;
      })
      .addCase(fetchEvents.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })

      // Fetch event by ID
      .addCase(fetchEventById.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchEventById.fulfilled, (state, action) => {
        state.loading = false;
        state.selectedEvent = action.payload;
      })
      .addCase(fetchEventById.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })

      // Create event
      .addCase(createEvent.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(createEvent.fulfilled, (state, action) => {
        state.loading = false;
        state.events = [action.payload, ...state.events];
      })
      .addCase(createEvent.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })

      // Update event
      .addCase(updateEvent.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(updateEvent.fulfilled, (state, action) => {
        state.loading = false;
        state.events = state.events.map((event) =>
          event.id === action.payload.id ? action.payload : event
        );
      })
      .addCase(updateEvent.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })

      // Delete event
      .addCase(deleteEvent.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(deleteEvent.fulfilled, (state, action) => {
        state.loading = false;
        state.events = state.events.filter((event) => event.id !== action.payload);
      })
      .addCase(deleteEvent.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export default eventSlice.reducer;
