import React from 'react'
import AboutSection from './AboutSection'
import TeamSection from './TeamSection'
import BoardSection from './BoardSection'

const About=()=> {
  return (
    <>
    <AboutSection />
    <TeamSection />
    <BoardSection />
    </>
  )
}

export default About