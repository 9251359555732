import React from 'react';
import { Link } from 'react-router-dom';

const TopHeader = () => {
  return (
    <div className="bg-gray-900 text-white text-sm py-2">
      <div className="mx-auto flex flex-col md:flex-row justify-between items-center px-4">
        
        {/* Social Media Icons - Hidden on Mobile */}
        <div className="hidden md:flex space-x-4">
          <a href="https://linkedin.com" target="_blank" rel="noopener noreferrer">
            <svg className="w-6 h-6" fill="currentColor" viewBox="0 0 24 24">
              <path d="M16.98 8.25c-1.66 0-2.55.94-2.98 1.6v-.01h-.01v-.01V8.5H11v9h3v-5c0-.5.04-1.02.37-1.33.33-.32.77-.52 1.23-.52 1.04 0 1.53.78 1.53 1.91v4.94h3V13.5c0-2.46-1.32-4.25-3.1-4.25zM6.5 8.5H3.5v9h3v-9zM5 7.1a1.76 1.76 0 110-3.51A1.76 1.76 0 015 7.1zm14-4.1H4A2 2 0 002 5v14c0 1.1.9 2 2 2h14a2 2 0 002-2V5a2 2 0 00-2-2z"/>
            </svg>
          </a>
          <a href="https://twitter.com" target="_blank" rel="noopener noreferrer">
            <svg className="w-6 h-6" fill="currentColor" viewBox="0 0 24 24">
              <path d="M22.46 6c-.77.34-1.5.57-2.35.69a4.17 4.17 0 001.83-2.34c-.83.5-1.74.86-2.72 1.05A4.14 4.14 0 0015.55 4c-2.3 0-4.17 1.87-4.17 4.17 0 .33.03.65.1.96C7.7 8.86 4.89 7.27 3.14 4.8c-.36.6-.57 1.3-.57 2.05 0 1.42.72 2.67 1.83 3.4-.68 0-1.3-.2-1.84-.51v.05c0 1.99 1.42 3.63 3.3 4a4.06 4.06 0 01-1.1.14c-.27 0-.53-.03-.79-.08.54 1.68 2.07 2.9 3.9 2.94a8.34 8.34 0 01-5.16 1.78c-.34 0-.67-.02-1-.06 1.85 1.2 4.07 1.91 6.45 1.91 7.72 0 11.94-6.39 11.94-11.94 0-.18-.01-.36-.02-.54.82-.59 1.53-1.33 2.1-2.17z"/>
            </svg>
          </a>
          <a href="https://facebook.com" target="_blank" rel="noopener noreferrer">
            <svg className="w-6 h-6" fill="currentColor" viewBox="0 0 24 24">
              <path d="M22 12c0-5.52-4.48-10-10-10S2 6.48 2 12c0 5 3.67 9.13 8.44 9.88V14.68h-2.54v-2.68h2.54V10.5c0-2.51 1.5-3.88 3.75-3.88 1.07 0 2 .08 2.28.12v2.64h-1.57c-1.23 0-1.47.58-1.47 1.43v1.88h2.92l-.39 2.68h-2.53v7.2C18.33 21.13 22 17 22 12z"/>
            </svg>
          </a>
        </div>

        {/* Navigation Links - Full Width on Mobile */}
        <div className="flex flex-col md:flex-row w-full md:w-auto md:space-x-4">
          <Link to={"/events"} className="hover:text-gray-300 w-full md:w-auto block text-center py-1 md:py-0">
            Events
          </Link>
          <Link to="#" className="hover:text-gray-300 w-full md:w-auto block text-center py-1 md:py-0">
            Collaborations
          </Link>
          <Link to={"/membership"} className="hover:text-gray-300 w-full md:w-auto block text-center py-1 md:py-0">
            Become a member
          </Link>
          <Link to="/contact" className="hover:text-gray-300 w-full md:w-auto block text-center py-1 md:py-0">
            Contact
          </Link>
        </div>
      </div>
    </div>
  );
};

export default TopHeader;
