import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchEvents } from "../../redux/eventSlice";
import FeaturedEvent from "./FeaturedEvent";
import UpcomingEvents from "./UpcomingEvents";
import FeaturedEventSkeleton from "./FeaturedEventSkeleton";
import UpcomingEventsSkeleton from "./UpcomingEventsSkeleton";

const EventsPage = () => {
  const dispatch = useDispatch();
  const { events, loading, error } = useSelector((state) => state.events);
  const [featuredEvent, setFeaturedEvent] = useState(null);
  const [upcomingEvents, setUpcomingEvents] = useState([]);

  useEffect(() => {
    dispatch(fetchEvents());
  }, [dispatch]);

  useEffect(() => {
    if (events.length > 0) {
      setFeaturedEvent(events[0]);
      setUpcomingEvents(events.slice(1));
    }
  }, [events]);

  const handleEventClick = (selectedEvent) => {
    const newUpcoming = [featuredEvent, ...upcomingEvents.filter((e) => e.id !== selectedEvent.id)];
    setFeaturedEvent(selectedEvent);
    setUpcomingEvents(newUpcoming);
  };

  return (
    <div className="bg-[#f3f6f3] text-gray-800 w-full">
      <main className="w-full max-w-[1440px] mx-auto py-12 px-4 md:px-8 grid grid-cols-1 lg:grid-cols-3 gap-8">
        {loading ? (
          <>
            <div className="lg:col-span-2">
              <FeaturedEventSkeleton />
            </div>
            <aside className="lg:col-span-1">
              <UpcomingEventsSkeleton />
            </aside>
          </>
        ) : error ? (
          <p className="text-red-500">{error}</p>
        ) : events.length > 0 ? (
          <>
            <div className="lg:col-span-2">
              {featuredEvent && (
                <FeaturedEvent
                  title={featuredEvent.title}
                  subtitle="Featured Event"
                  image={featuredEvent.imageUrl}
                  description={featuredEvent.description}
                />
              )}
            </div>
            <aside className="lg:col-span-1">
              <UpcomingEvents
                events={upcomingEvents}
                onEventClick={handleEventClick}
              />
            </aside>
          </>
        ) : (
          <p>No events available.</p>
        )}
      </main>
    </div>
  );
};

export default EventsPage;
