import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import API from "./api";

// Fetch all team members
export const fetchTeamMembers = createAsyncThunk(
  "team/fetchTeamMembers",
  async (_, { rejectWithValue }) => {
    try {
      const response = await API.get("/team-members");
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data?.message || "Failed to fetch team members.");
    }
  }
);

// Add a new team member
export const addTeamMember = createAsyncThunk(
  "team/addTeamMember",
  async (formData, { rejectWithValue }) => {
    try {
      const response = await API.post("/team-members", formData, {
        headers: { "Content-Type": "multipart/form-data" },
      });
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data?.message || "Failed to add team member.");
    }
  }
);

// Update a team member
export const updateTeamMember = createAsyncThunk(
    "team/updateTeamMember",
    async ({ id, teamData }, { rejectWithValue }) => {
      try {
        const response = await API.put(`/team-members/${id}`, teamData, {
          headers: { "Content-Type": "multipart/form-data" },
        });
        return response.data;
      } catch (error) {
        return rejectWithValue(error.response?.data?.message || "Failed to update team member.");
      }
    }
  );  

// Delete a team member
export const deleteTeamMember = createAsyncThunk(
  "team/deleteTeamMember",
  async (id, { rejectWithValue }) => {
    try {
      await API.delete(`/team-members/${id}`);
      return id;
    } catch (error) {
      return rejectWithValue(error.response?.data?.message || "Failed to delete team member.");
    }
  }
);

const teamSlice = createSlice({
  name: "team",
  initialState: {
    members: [],
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchTeamMembers.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchTeamMembers.fulfilled, (state, action) => {
        state.loading = false;
        state.members = action.payload;
      })
      .addCase(fetchTeamMembers.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })

      .addCase(addTeamMember.fulfilled, (state, action) => {
        state.members.unshift(action.payload);
      })

      .addCase(updateTeamMember.fulfilled, (state, action) => {
        state.members = state.members.map((member) =>
          member.id === action.payload.id ? action.payload : member
        );
      })

      .addCase(deleteTeamMember.fulfilled, (state, action) => {
        state.members = state.members.filter((member) => member.id !== action.payload);
      });
  },
});

export default teamSlice.reducer;
