import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import API from "./api";

// Fetch all partners
export const fetchPartners = createAsyncThunk("partners/fetchPartners", async (_, { rejectWithValue }) => {
  try {
    const response = await API.get("/partners");
    return response.data;
  } catch (error) {
    return rejectWithValue(error.response?.data?.message || "Failed to fetch partner.");
  }
});

// Fetch partner by ID
export const fetchPartnerById = createAsyncThunk("partners/fetchPartnerById", async (id, { rejectWithValue }) => {
  try {
    const response = await API.get(`/partners/${id}`);
    return response.data;
  } catch (error) {
    return rejectWithValue(error.response?.data?.message || "Failed to fetch partner.");
  }
});

// Create an partner
export const createPartner = createAsyncThunk(
    "partners/createPartner",
    async (partnerData, { rejectWithValue }) => {
      try {
        const response = await API.post("/partners", partnerData, {
          headers: { "Content-Type": "multipart/form-data" },
        });
        return response.data;
      } catch (error) {
        return rejectWithValue(error.response?.data?.message || "Failed to create a partner.");
      }
    }
  );

// Update a partner
export const updatePartner = createAsyncThunk(
  "partners/updatePartner",
  async ({ id, partnerData }, { rejectWithValue }) => {
    try {
      const response = await API.put(`/partners/${id}`, partnerData, {
        headers: { "Content-Type": "multipart/form-data" },
      });
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data?.message || "Failed to update a partner.");
    }
  }
);

// Delete a partner
export const deletePartner = createAsyncThunk("partners/deletePartner", async (id, { rejectWithValue }) => {
  try {
    await API.delete(`/partners/${id}`);
    return id;
  } catch (error) {
    return rejectWithValue(error.response?.data?.message || "Failed to delete a partner.");
  }
});

const partnerSlice = createSlice({
  name: "partners",
  initialState: {
    partners: [],
    selectedPartner: null,
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      // Fetch all partners
      .addCase(fetchPartners.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchPartners.fulfilled, (state, action) => {
        state.loading = false;
        state.partners = action.payload;
      })
      .addCase(fetchPartners.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })

      // Fetch partner by ID
      .addCase(fetchPartnerById.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchPartnerById.fulfilled, (state, action) => {
        state.loading = false;
        state.selectedPartner = action.payload;
      })
      .addCase(fetchPartnerById.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })

      // Create a partner
      .addCase(createPartner.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(createPartner.fulfilled, (state, action) => {
        state.loading = false;
        state.partners = [action.payload, ...state.partners];
      })
      .addCase(createPartner.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })

      // Update Partner
      .addCase(updatePartner.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(updatePartner.fulfilled, (state, action) => {
        state.loading = false;
        state.partners = state.partners.map((partner) =>
          partner.id === action.payload.id ? action.payload : partner
        );
      })
      .addCase(updatePartner.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })

      // Delete partner
      .addCase(deletePartner.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(deletePartner.fulfilled, (state, action) => {
        state.loading = false;
        state.partners = state.partners.filter((partner) => partner.id !== action.payload);
      })
      .addCase(deletePartner.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export default partnerSlice.reducer;
