import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { fetchTeamMembers } from "../../redux/teamSlice";
import BoardMemberCard from "./BoardMemberCard";
import { useInView } from "react-intersection-observer";

const BoardSection = () => {
  const dispatch = useDispatch();
  const { members, loading, error } = useSelector((state) => state.team);
  const { ref, inView } = useInView({ threshold: 0.2 });

  useEffect(() => {
    dispatch(fetchTeamMembers());
  }, [dispatch]);

  return (
    <div
      ref={ref}
      className={`bg-blue-50 py-12 transform transition-all duration-1000 ${
        inView ? "opacity-100 translate-x-0" : "opacity-0 -translate-x-10"
      }`}
    >
      <div className="container mx-auto px-6 space-y-8">
        <div>
          <h3 className="text-blue-600 text-2xl font-semibold font-big-shoulders">
            Our Board
          </h3>
          <p className="text-gray-700 mt-4 leading-relaxed font-noto-sans">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras gravida scelerisque felis eu fermentum. Nam ac eleifend nulla. Proin elit ante, tristique at vulputate non, hendrerit sed orci. Aenean mattis, orci ac feugiat tempus, urna mauris congue tortor, et pharetra diam massa sed nisi.
          </p>
        </div>

        {loading ? (
          <p>Loading team members...</p>
        ) : error ? (
          <p className="text-red-500">{error}</p>
        ) : (
          <div className="grid grid-cols-2 md:grid-cols-4 gap-8 py-8">
            {members.map((member, index) => (
              <BoardMemberCard
                key={member.id}
                name={member.name}
                role={member.title}
                image={member.imageUrl}
                index={index}
              />
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default BoardSection;
