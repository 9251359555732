import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import API from "./api";

const API_URL = "https://ubuntu-backend-745v.onrender.com/api/interventions";
// const API_URL = "http://localhost:3000/api/interventions";

// Fetch all interventions
export const fetchInterventions = createAsyncThunk("interventions/fetchInterventions", 
    async (_, { rejectWithValue }) => {
    try {
      const response = await API.get(API_URL);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data?.message || "Failed to fetch Interventions.");
    }
  });

// Fetch intervention by ID
export const fetchInterventionById = createAsyncThunk("interventions/fetchInterventionById", async (id, { rejectWithValue }) => {
    try {
      const response = await API.get(`/interventions/${id}`);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data?.message || "Failed to fetch interventions.");
    }
  });


// Create an interventions
export const createIntervention = createAsyncThunk(
    "interventions/createIntervention",
    async (interventionData, { rejectWithValue }) => {
      try {
        const response = await API.post("/interventions", interventionData, {
          headers: { "Content-Type": "multipart/form-data" },
        });
        return response.data;
      } catch (error) {
        return rejectWithValue(error.response?.data?.message || "Failed to create interventions.");
      }
    }
  );


// Update an intervention
export const updateIntervention = createAsyncThunk(
    "interventions/updateIntervention",
    async ({ id, interventionData }, { rejectWithValue }) => {
      try {
        const response = await API.put(`/interventions/${id}`, interventionData, {
          headers: { "Content-Type": "multipart/form-data" },
        });
        return response.data;
      } catch (error) {
        return rejectWithValue(error.response?.data?.message || "Failed to update interventions.");
      }
    }
  );
  
  


// Delete an Intervention
export const deleteIntervention = createAsyncThunk("interventions/deleteIntervention", async (id, { rejectWithValue }) => {
    try {
      await API.delete(`/interventions/${id}`);
      return id;
    } catch (error) {
      return rejectWithValue(error.response?.data?.message || "Failed to delete interventions.");
    }
  });

  const interventionSlice = createSlice({
    name: "interventions",
    initialState: {
      interventions: [], // List of events
      selectedIntervention: null, // For single event operations
      loading: false,
      error: null,
    },
    reducers: {},
    extraReducers: (builder) => {
      builder
        // Fetch all events
        .addCase(fetchInterventions.pending, (state) => {
          state.loading = true;
          state.error = null;
        })
        .addCase(fetchInterventions.fulfilled, (state, action) => {
          state.loading = false;
          state.interventions = action.payload;
        })
        .addCase(fetchInterventions.rejected, (state, action) => {
          state.loading = false;
          state.error = action.payload;
        })
  
        // Fetch event by ID
        .addCase(fetchInterventionById.pending, (state) => {
          state.loading = true;
          state.error = null;
        })
        .addCase(fetchInterventionById.fulfilled, (state, action) => {
          state.loading = false;
          state.selectedIntervention = action.payload;
        })
        .addCase(fetchInterventionById.rejected, (state, action) => {
          state.loading = false;
          state.error = action.payload;
        })
  
        // Create event
        .addCase(createIntervention.pending, (state) => {
          state.loading = true;
          state.error = null;
        })
        .addCase(createIntervention.fulfilled, (state, action) => {
          state.loading = false;
          state.interventions = [action.payload, ...state.interventions];
        })
        .addCase(createIntervention.rejected, (state, action) => {
          state.loading = false;
          state.error = action.payload;
        })
  
        // Update intervention
        .addCase(updateIntervention.pending, (state) => {
          state.loading = true;
          state.error = null;
        })
        .addCase(updateIntervention.fulfilled, (state, action) => {
          state.loading = false;
          state.interventions = state.interventions.map((intervention) =>
            intervention.id === action.payload.id ? action.payload : intervention
          );
        })
        .addCase(updateIntervention.rejected, (state, action) => {
          state.loading = false;
          state.error = action.payload;
        })
  
        // Delete Intervention
        .addCase(deleteIntervention.pending, (state) => {
          state.loading = true;
          state.error = null;
        })
        .addCase(deleteIntervention.fulfilled, (state, action) => {
          state.loading = false;
          state.interventions = state.interventions.filter((intervention) => intervention.id !== action.payload);
        })
        .addCase(deleteIntervention.rejected, (state, action) => {
          state.loading = false;
          state.error = action.payload;
        });
    },
  });

export default interventionSlice.reducer;
