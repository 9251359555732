import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import API from "./api";

// Thunk to submit hire request
export const submitHireRequest = createAsyncThunk(
  "hire/submitHireRequest",
  async (formData, { rejectWithValue }) => {
    try {
      const response = await API.post("/hire", formData);
      // const response = await API.post("/hire", formData);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data?.message || "Error submitting hire request.");
    }
  }
);

export const fetchHireRequests = createAsyncThunk(
  "hire/fetchHireRequests",
  async (_, { rejectWithValue }) => {
    try {
      const response = await API.get("/hire");
      // const response = await API.get("/hire");
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data?.message || "Failed to fetch hire requests.");
    }
  }
);

const hireSlice = createSlice({
  name: "hire",
  initialState: {
    hireRequests: [],
    loading: false,
    success: false,
    error: null,
  },
  reducers: {
    resetState: (state) => {
      state.loading = false;
      state.success = false;
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(submitHireRequest.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.success = false;
      })
      .addCase(submitHireRequest.fulfilled, (state) => {
        state.loading = false;
        state.success = true;
        state.error = null;
      })
      .addCase(submitHireRequest.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
        state.success = false;
      })
      
      .addCase(fetchHireRequests.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchHireRequests.fulfilled, (state, action) => {
        state.loading = false;
        state.hireRequests = action.payload;
      })
      .addCase(fetchHireRequests.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export const { resetState } = hireSlice.actions;

export default hireSlice.reducer;
