import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchEvents, deleteEvent } from "../../redux/eventSlice";
import { toast } from "react-toastify";
import PostEventForm from "../../page/PostEventForm";
import Sidebar from "./Sidebar";

const AdminEventsPage = () => {
  const dispatch = useDispatch();
  const { events, loading, error } = useSelector((state) => state.events);

  const [editingEvent, setEditingEvent] = useState(null);
  const [showCreateForm, setShowCreateForm] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState(null); // Modal state for viewing details

  useEffect(() => {
    dispatch(fetchEvents());
  }, [dispatch]);

  const handleDelete = (id) => {
    if (window.confirm("Are you sure you want to delete this event?")) {
      dispatch(deleteEvent(id))
        .unwrap()
        .then(() => toast.success("Event deleted successfully!"))
        .catch(() => toast.error("Failed to delete event"));
    }
  };

  const handleEdit = (event) => {
    setEditingEvent(event);
    setShowCreateForm(false);
  };

  const handleCloseEdit = () => {
    setEditingEvent(null);
  };

  const handleShowCreateForm = () => {
    setShowCreateForm(true);
    setEditingEvent(null);
  };

  const truncateText = (text, maxLength) => {
    if (text.length <= maxLength) return text;
    return text.slice(0, maxLength) + "...";
  };

  const openModal = (event) => {
    setSelectedEvent(event);
  };

  const closeModal = () => {
    setSelectedEvent(null);
  };

  return (
    <div className="flex">
      {/* Sidebar */}
      <Sidebar />

      {/* Main Content */}
      <div className="flex-1 p-8 bg-gray-100">
        <h1 className="text-2xl font-bold mb-6">Event Management</h1>

        {/* Create Event Form */}
        {showCreateForm && (
          <div className="mb-8 bg-white p-4 rounded shadow">
            <h2 className="text-xl font-bold mb-4">Create New Event</h2>
            <PostEventForm />
            <button
              className="text-red-500 mt-4"
              onClick={() => setShowCreateForm(false)}
            >
              Cancel
            </button>
          </div>
        )}

        {/* Edit Event Form */}
        {editingEvent && (
          <div className="mb-8 bg-white p-4 rounded shadow">
            <h2 className="text-xl font-bold mb-4">Edit Event</h2>
            <PostEventForm
              editMode
              existingEvent={editingEvent}
              onClose={handleCloseEdit}
            />
          </div>
        )}

        {/* Event List */}
        {loading ? (
          <p>Loading events...</p>
        ) : error ? (
          <p className="text-red-500">{error}</p>
        ) : events.length > 0 ? (
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
            {events.map((event) => (
              <div
                key={event.id}
                className="bg-white rounded shadow p-4 flex flex-col justify-between"
              >
                <div>
                  <img
                    src={event.imageUrl}
                    alt={event.title}
                    className="w-full h-40 object-cover rounded mb-4"
                  />
                  <h3 className="text-lg font-bold">{event.title}</h3>
                  <p className="text-sm text-gray-600">
                    {truncateText(event.description, 100)}{" "}
                    <button
                      onClick={() => openModal(event)}
                      className="text-blue-500 underline"
                    >
                      Read more
                    </button>
                  </p>
                </div>
                <div className="mt-4 flex justify-between">
                  <button
                    onClick={() => handleEdit(event)}
                    className="bg-blue-500 text-white px-4 py-2 rounded"
                  >
                    Edit
                  </button>
                  <button
                    onClick={() => handleDelete(event.id)}
                    className="bg-red-500 text-white px-4 py-2 rounded"
                  >
                    Delete
                  </button>
                </div>
              </div>
            ))}
          </div>
        ) : (
          <p>No events found.</p>
        )}

        {/* Button to Toggle Create Form */}
        {!showCreateForm && !editingEvent && (
          <button
            onClick={handleShowCreateForm}
            className="bg-green-500 text-white px-4 py-2 rounded mt-4"
          >
            Create New Event
          </button>
        )}
      </div>

      {/* Modal for Viewing Full Event Details */}
      {selectedEvent && (
        <div
          className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center"
          onClick={closeModal}
        >
          <div
            className="bg-white p-6 rounded shadow max-w-lg w-full"
            onClick={(e) => e.stopPropagation()}
          >
            <h2 className="text-xl font-bold mb-4">{selectedEvent.title}</h2>
            <img
              src={selectedEvent.imageUrl}
              alt={selectedEvent.title}
              className="w-full h-40 object-cover rounded mb-4"
            />
            <p className="text-gray-700">{selectedEvent.description}</p>
            <button
              className="mt-4 text-red-500"
              onClick={closeModal}
            >
              Close
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default AdminEventsPage;
