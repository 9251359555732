import React from 'react';

const FormField = ({ label, name, type = "text", placeholder, value, onChange }) => (
  <div>
    <label className="block text-gray-700 font-semibold font-noto-sans text-sm sm:text-base">{label}</label>
    <input
      type={type}
      name={name}
      value={value}
      onChange={onChange}
      className="w-full p-3 border rounded-md mt-2"
      placeholder={placeholder}
    />
  </div>
);

export default FormField;
