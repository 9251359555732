import { AiOutlineDashboard, AiOutlineCalendar, AiOutlineUser, AiOutlineForm, AiOutlineMenu } from "react-icons/ai";
import { useState } from "react";
import { Link } from "react-router-dom";

function Sidebar() {
  const [isOpen, setIsOpen] = useState(false);

  const handleToggleSidebar = () => {
    setIsOpen(!isOpen);
  };

  return (
    <>
      <button
        className="lg:hidden p-4"
        onClick={handleToggleSidebar}
      >
        <AiOutlineMenu className="text-2xl" />
      </button>

      {isOpen && (
        <div
          className="fixed inset-0 bg-black bg-opacity-50 lg:hidden z-20"
          onClick={handleToggleSidebar}
        />
      )}

      <div
        className={`fixed lg:static top-0 left-0 w-64 h-screen bg-gray-800 text-white flex flex-col z-30 transform ${
          isOpen ? "translate-x-0" : "-translate-x-full"
        } lg:translate-x-0 transition-transform duration-300`}
      >
        <div className="text-xl font-bold p-4"><Link to="/">UBUNTU VALLEY</Link></div>
        <nav className="flex flex-col gap-4 p-4">
          <Link to="/dashboard" className="flex items-center gap-2 hover:bg-gray-700 p-2 rounded">
            <AiOutlineDashboard /> Dashboard
          </Link>
          <Link to="/admin-events" className="flex items-center gap-2 hover:bg-gray-700 p-2 rounded">
            <AiOutlineCalendar /> Event Management
          </Link>
          <Link to="/admin-teams" className="flex items-center gap-2 hover:bg-gray-700 p-2 rounded">
            <AiOutlineUser /> Manage Teams
          </Link>
          <Link to="/admin-intervention" className="flex items-center gap-2 hover:bg-gray-700 p-2 rounded">
            <AiOutlineUser /> Interventions
          </Link>
          <Link to="/admin-partners" className="flex items-center gap-2 hover:bg-gray-700 p-2 rounded">
            <AiOutlineUser /> Partners
          </Link>
          <Link to="/admin-hubs" className="flex items-center gap-2 hover:bg-gray-700 p-2 rounded">
            <AiOutlineUser /> Hubs
          </Link>
          <a href="#" className="flex items-center gap-2 hover:bg-gray-700 p-2 rounded">
            <AiOutlineUser /> Profile
          </a>
        </nav>
      </div>
    </>
  );
}

export default Sidebar;
