import React from "react";
import UpcomingEventItem from "./UpcomingEventItem";
import SkeletonLoader from "./SkeletonLoader";  // Import SkeletonLoader

const UpcomingEvents = ({ events, onEventClick }) => {
  return (
    <aside className="bg-[#f5f8f5] rounded-lg shadow-lg p-6 w-full">
      <h3 className="text-xl md:text-2xl font-bold text-blue-600 mb-4 md:mb-6 font-big-shoulders">
        Upcoming Events
      </h3>
      <div className="space-y-4 md:space-y-6">
        {events.length === 0 ? (
          <>
            <SkeletonLoader height="h-20" margin="mb-4" /> {/* Upcoming Event Skeleton */}
            <SkeletonLoader height="h-20" margin="mb-4" />
            <SkeletonLoader height="h-20" margin="mb-4" />
          </>
        ) : (
          events.map((event, index) => (
            <UpcomingEventItem
              key={event.id}
              title={event.title}
              description={event.description}
              image={event.imageUrl}
              index={index}
              onClick={() => onEventClick(event)}
            />
          ))
        )}
      </div>
    </aside>
  );
};

export default UpcomingEvents;
