import React from "react";
import { useInView } from "react-intersection-observer";
import image1 from "../../assets/events.png";
import mtn from "../../assets/mtn.png";
import airtel from "../../assets/airtel.png";
import visa from "../../assets/visa.png";
import paypal from "../../assets/paypal.png";

const DonateSection = () => {
  const { ref, inView } = useInView({ threshold: 0.2 });

  return (
    <section
      ref={ref}
      className={`py-20 bg-[#F5F8F2] px-6 md:px-12 lg:px-20 transform transition-transform duration-1000 ${
        inView ? "opacity-100 translate-y-0" : "opacity-0 translate-y-10"
      }`}
    >
      <div className="max-w-8xl mx-auto grid grid-cols-1 lg:grid-cols-2 gap-12 items-start">
        {/* Left Side: Image and Description */}
        <div className="space-y-6">
          <img
            src={image1}
            alt="Donate"
            className="rounded-lg object-cover w-full h-64 lg:h-96"
          />
          <p className="text-gray-700 font-noto-sans text-base md:text-lg leading-relaxed text-left">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras
            gravida scelerisque felis eu fermentum. Nam ac eleifend nulla. Proin
            elit ante, tristique at vulputate non.
          </p>
        </div>

        {/* Right Side: Form */}
        <div className="bg-white p-6 lg:p-8 rounded-lg shadow-lg space-y-6">
          <h1 className="text-3xl md:text-4xl font-bold text-blue-600 font-big-shoulders text-left">
            Donate
          </h1>

          <form className="space-y-6">
            {/* Full Name */}
            <div>
              <label
                htmlFor="fullName"
                className="block text-gray-600 text-sm mb-1"
              >
                Full Names
              </label>
              <input
                id="fullName"
                type="text"
                placeholder="Full Names"
                className="w-full p-3 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
              />
            </div>

            {/* Email */}
            <div>
              <label
                htmlFor="email"
                className="block text-gray-600 text-sm mb-1"
              >
                Email
              </label>
              <input
                id="email"
                type="email"
                placeholder="Email"
                className="w-full p-3 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
              />
            </div>

            {/* Donation Method */}
            <div>
              <p className="text-gray-600 text-sm md:text-base mb-2">
                Donation Method
              </p>
              <div className="flex flex-wrap gap-4">
                {["RWF", "$", "€", "£"].map((method) => (
                  <button
                    type="button"
                    key={method}
                    className="px-4 py-2 border border-gray-300 rounded-md hover:bg-blue-500 hover:text-white focus:outline-none"
                  >
                    {method}
                  </button>
                ))}
              </div>
            </div>

            {/* Donation Amount */}
            <div>
              <p className="text-gray-600 text-sm md:text-base mb-2">Amount</p>
              <div className="flex flex-wrap gap-4">
                {["RWF 5,000", "RWF 25,000", "RWF 50,000"].map((amount) => (
                  <button
                    type="button"
                    key={amount}
                    className="px-4 py-2 border border-gray-300 rounded-md hover:bg-gray-200"
                  >
                    {amount}
                  </button>
                ))}
                <input
                  type="text"
                  placeholder="Enter Custom Amount"
                  className="flex-1 p-3 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                />
              </div>
            </div>

            {/* Payment Method */}
            <div>
              <p className="text-gray-600 text-sm md:text-base mb-2">
                Payment Method
              </p>
              <div className="flex flex-wrap gap-4">
                {[mtn, airtel, visa, paypal].map((icon, index) => (
                  <img
                    key={index}
                    src={icon}
                    alt="Payment Method"
                    className="h-12 w-auto border border-gray-300 p-2 rounded-md"
                  />
                ))}
              </div>
            </div>
          </form>
        </div>
      </div>
    </section>
  );
};

export default DonateSection;
