import React, { useState, useEffect } from "react";
import { useInView } from "react-intersection-observer";
import { useSelector, useDispatch } from "react-redux";
import { fetchHubs } from "../../redux/hubSlice";
import { Link } from "react-router-dom";

const HubSection = () => {
  const { ref, inView } = useInView({ threshold: 0.2 });
  const [currentIndex, setCurrentIndex] = useState(0);
  const dispatch = useDispatch();
  const { hubs, loading, error } = useSelector((state) => state.hubs);

  useEffect(() => {
    dispatch(fetchHubs());
  }, [dispatch]);

  useEffect(() => {
    const interval = setInterval(() => {
      if (hubs.length > 0) {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % hubs.length);
      }
    }, 5000);
    return () => clearInterval(interval);
  }, [hubs]);

  if (loading) {
    return (
      <section className="py-20 bg-[#f3f6f3] px-6 md:px-12 lg:px-20 text-center">
        <p className="text-blue-500 text-lg">Loading hubs...</p>
      </section>
    );
  }

  if (error) {
    return (
      <section className="py-20 bg-[#f3f6f3] px-6 md:px-12 lg:px-20 text-center">
        <p className="text-red-500 text-lg">Error: {error}</p>
      </section>
    );
  }

  if (hubs.length === 0) {
    return (
      <section className="py-20 bg-[#f3f6f3] px-6 md:px-12 lg:px-20 text-center">
        <p className="text-gray-500 text-lg">No hubs available at the moment.</p>
      </section>
    );
  }

  return (
    <section
      ref={ref}
      className={`py-20 bg-[#f3f6f3] px-6 md:px-12 lg:px-20 transform transition-transform duration-1000 ${
        inView ? "opacity-100 translate-y-0" : "opacity-0 translate-y-10"
      }`}
    >
      <div className="container mx-auto">
        <h2 className="text-3xl md:text-4xl font-bold text-blue-600 mb-8 font-big-shoulders text-left">
          Explore Our Hubs
        </h2>

        <div className="relative flex items-center justify-center">
          {/* Previous Button */}
          <button
            onClick={() =>
              setCurrentIndex((prevIndex) => (prevIndex - 1 + hubs.length) % hubs.length)
            }
            className="absolute left-4 bg-blue-500 bg-opacity-75 text-white hover:bg-opacity-100 text-3xl md:text-4xl z-10 p-2 rounded-full"
          >
            &#8249;
          </button>

          {/* Hub Card */}
          <Link to="/hubs">
          <div
            className={`bg-white shadow-xl transform transition-transform duration-500 ${
              inView ? "scale-105" : "scale-100"
            } flex flex-col md:flex-row w-full max-w-5xl items-center h-[60vh] md:h-[70vh] rounded-lg overflow-hidden`}
          >
            <img
              src={hubs[currentIndex].imageUrl} // Adjust field names based on your backend data
              alt={hubs[currentIndex].title}
              className="w-full md:w-1/2 h-full object-cover"
            />
            <div className="p-6 md:p-10 w-full md:w-1/2 h-full flex flex-col justify-center text-left">
              <h3 className="text-2xl md:text-3xl font-bold text-green-600 font-big-shoulders">
                {hubs[currentIndex].title}
              </h3>
              <p className="mt-4 text-gray-800 font-noto-sans text-sm md:text-lg leading-loose">
                {hubs[currentIndex].description}
              </p>
            </div>
          </div>
          </Link>

          {/* Next Button */}
          <button
            onClick={() =>
              setCurrentIndex((prevIndex) => (prevIndex + 1) % hubs.length)
            }
            className="absolute right-4 bg-blue-500 bg-opacity-75 text-white hover:bg-opacity-100 text-3xl md:text-4xl z-10 p-2 rounded-full"
          >
            &#8250;
          </button>
        </div>
        {/* Indicator */}
        <div className="flex justify-center mt-4 space-x-2">
          {hubs.map((_, index) => (
            <span
              key={index}
              className={`w-3 h-3 rounded-full ${
                index === currentIndex ? "bg-blue-500" : "bg-gray-300"
              }`}
            ></span>
          ))}
        </div>
      </div>
    </section>
  );
};

export default HubSection;
