import React, { useState, useEffect } from 'react';
import logo from '../assets/Logo.png';
import { Link, useLocation } from 'react-router-dom';
import { RiMenu3Line, RiCloseLine } from 'react-icons/ri'; // Icons for the menu

const Header = () => {
  const location = useLocation();
  const [isScrolled, setIsScrolled] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(window.scrollY > 50);
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <header
      className={`bg-[#f3f6f3] w-full z-10 py-4 transition-all duration-300 shadow-md ${
        isScrolled ? 'fixed top-0' : 'relative'
      }`}
    >
      <div className="mx-4 flex justify-between items-center">
        <div>
          <Link to="/">
            <img src={logo} alt="Ubuntu Valley" className="h-8 md:h-10" />
          </Link>
        </div>
        {/* Hamburger menu for mobile */}
        <div className="md:hidden" onClick={toggleMenu}>
          {isMenuOpen ? (
            <RiCloseLine className="text-2xl cursor-pointer" />
          ) : (
            <RiMenu3Line className="text-2xl cursor-pointer" />
          )}
        </div>
        {/* Navigation links */}
        <nav
          className={`space-x-8 text-sm font-semibold text-gray-800 md:flex ${
            isMenuOpen ? 'block' : 'hidden'
          } md:space-x-4 md:static absolute top-16 left-0 w-full bg-[#f3f6f3] md:bg-transparent md:w-auto p-4 md:p-0`}
        >
          <Link to="ventures" className="hover:text-gray-500 block md:inline ml-4 pl-3">
            VENTURES
          </Link>
          <Link to="/hubs" className="hover:text-gray-500 block md:inline ml-4 pl-3">
            HUBS
          </Link>
          <Link to="#" className="hover:text-gray-500 block md:inline">
            INTERVENTIONS
          </Link>
          <Link to="/support" className="hover:text-gray-500 block md:inline">
            SUPPORT US
          </Link>
          <Link
            to="/about"
            className={`${
              location.pathname === '/about' ? 'text-blue-500 font-bold' : 'hover:text-gray-500'
            } block md:inline`}
          >
            ABOUT US
          </Link>
        </nav>
      </div>
    </header>
  );
};

export default Header;
