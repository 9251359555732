import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchHubs, deleteHub } from "../../../redux/hubSlice";
import { toast } from "react-toastify";
import PostHubForm from "../../../page/PostHubForm";
import Sidebar from "../Sidebar";

const AdminHubsPage = () => {
  const dispatch = useDispatch();
  const { hubs, loading, error } = useSelector((state) => state.hubs);

  const [editingHub, setEditingHub] = useState(null);
  const [showCreateForm, setShowCreateForm] = useState(false);
  const [selectedHub, setSelectedHub] = useState(null);

  useEffect(() => {
    dispatch(fetchHubs());
  }, [dispatch]);

  const handleDelete = (id) => {
    if (window.confirm("Are you sure you want to delete this hub?")) {
      dispatch(deleteHub(id))
        .unwrap()
        .then(() => toast.success("Hub deleted successfully!"))
        .catch(() => toast.error("Failed to delete Hub"));
    }
  };

  const handleEdit = (hub) => {
    setEditingHub(hub);
    setShowCreateForm(false);
  };

  const handleCloseEdit = () => {
    setEditingHub(null);
  };

  const handleShowCreateForm = () => {
    setShowCreateForm(true);
    setEditingHub(null);
  };

  const truncateText = (text, maxLength) => {
    if (text.length <= maxLength) return text;
    return text.slice(0, maxLength) + "...";
  };

  const openModal = (hub) => {
    setSelectedHub(hub);
  };

  const closeModal = () => {
    setSelectedHub(null);
  };

  return (
    <div className="flex">
      {/* Sidebar */}
      <Sidebar />

      {/* Main Content */}
      <div className="flex-1 p-8 bg-gray-100">
        <h1 className="text-2xl font-bold mb-6">Hub Management</h1>

        {/* Create Hub Form */}
        {showCreateForm && (
          <div className="mb-8 bg-white p-4 rounded shadow">
            <h2 className="text-xl font-bold mb-4">Create New Hub</h2>
            <PostHubForm />
            <button
              className="text-red-500 mt-4"
              onClick={() => setShowCreateForm(false)}
            >
              Cancel
            </button>
          </div>
        )}

        {/* Edit Hub Form */}
        {editingHub && (
          <div className="mb-8 bg-white p-4 rounded shadow">
            <h2 className="text-xl font-bold mb-4">Edit Hub</h2>
            <PostHubForm
              editMode
              existingHub={editingHub}
              onClose={handleCloseEdit}
            />
          </div>
        )}

        {/* Hubs List */}
        {loading ? (
          <p>Loading Hubs...</p>
        ) : error ? (
          <p className="text-red-500">{error}</p>
        ) : hubs.length > 0 ? (
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
            {hubs.map((hub) => (
              <div
                key={hub.id}
                className="bg-white rounded shadow p-4 flex flex-col justify-between"
              >
                <div>
                  <img
                    src={hub.imageUrl}
                    alt={hub.title}
                    className="w-full h-40 object-cover rounded mb-4"
                  />
                  <h3 className="text-lg font-bold">{hub.title}</h3>
                  <p className="text-sm text-gray-600">
                    {truncateText(hub.description, 100)}{" "}
                    <button
                      onClick={() => openModal(hub)}
                      className="text-blue-500 underline"
                    >
                      Read more
                    </button>
                  </p>
                </div>
                <div className="mt-4 flex justify-between">
                  <button
                    onClick={() => handleEdit(hub)}
                    className="bg-blue-500 text-white px-4 py-2 rounded"
                  >
                    Edit
                  </button>
                  <button
                    onClick={() => handleDelete(hub.id)}
                    className="bg-red-500 text-white px-4 py-2 rounded"
                  >
                    Delete
                  </button>
                </div>
              </div>
            ))}
          </div>
        ) : (
          <p>No hubs found.</p>
        )}

        {/* Button to Toggle Create Form */}
        {!showCreateForm && !editingHub && (
          <button
            onClick={handleShowCreateForm}
            className="bg-green-500 text-white px-4 py-2 rounded mt-4"
          >
            Create New Hub
          </button>
        )}
      </div>

      {/* Modal for Viewing Full Hub's Details */}
      {selectedHub && (
        <div
          className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center"
          onClick={closeModal}
        >
          <div
            className="bg-white p-6 rounded shadow max-w-lg w-full"
            onClick={(e) => e.stopPropagation()}
          >
            <h2 className="text-xl font-bold mb-4">{selectedHub.title}</h2>
            <img
              src={selectedHub.imageUrl}
              alt={selectedHub.title}
              className="w-full h-40 object-cover rounded mb-4"
            />
            <p className="text-gray-700">{selectedHub.description}</p>
            <button
              className="mt-4 text-red-500"
              onClick={closeModal}
            >
              Close
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default AdminHubsPage;
