import { configureStore } from '@reduxjs/toolkit';
import membershipReducer from './membershipSlice';
import hireReducer from "./hireSlice";
import authReducer from "./authSlice";
import eventReducer from './eventSlice';
import teamReducer from './teamSlice';
import interventionReducer from './interventionSlice';
import hubReducer from './hubSlice';
import partnerReducer from './partnerSlice';
import contactReducer from './contactSlice'

const store = configureStore({
  reducer: {
    membership: membershipReducer,
    hire: hireReducer,
    auth: authReducer,
    events: eventReducer,
    team: teamReducer,
    interventions: interventionReducer,
    hubs : hubReducer,
    partners: partnerReducer,
    contact: contactReducer
  },
});

export default store;
