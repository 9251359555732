const UpcomingEventsSkeleton = () => {
    return (
      <aside className="bg-[#f5f8f5] rounded-lg shadow-lg p-6 w-full animate-pulse">
        <div className="h-10 bg-gray-300 rounded-md mb-4 w-1/2"></div>
        <div className="space-y-4">
          {[...Array(3)].map((_, index) => (
            <div key={index} className="h-20 bg-gray-300 rounded-md mb-4"></div>
          ))}
        </div>
      </aside>
    );
  };
  
  export default UpcomingEventsSkeleton;
  