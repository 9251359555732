import React from "react";
import image1 from "../../assets/Group 22.png";
import image2 from "../../assets/Group 8.png";

// PartnerSection Component
const PartnerSection = ({ title, description, partners }) => {
  return (
    <section className="py-8 bg-gray-50">
      <div className="container mx-auto px-4 py-12">
        {/* Section Title */}
        <h2 className="text-2xl font-bold text-green-600 mb-2">{title}</h2>
        <p className="text-gray-700 mb-6">{description}</p>

        {/* Logo Grid */}
        <div className="grid grid-cols-3 md:grid-cols-4 lg:grid-cols-5 gap-4 py-10">
          {partners.map((partner, index) => (
            <div
              key={index}
              className="flex justify-center items-center p-4 bg-white shadow rounded-lg"
            >
              <img
                src={partner.image}
                alt={partner.name}
                className="h-12 w-12 md:h-16 md:w-16 object-contain"
              />
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

// StartupsPage Component
const StartupsPage = () => {
  // Example Partner Data
  const startupPartners = [
    { name: "Startup 1", image: image1 },
    { name: "Startup 2", image: image2 },
    { name: "Startup 3", image: image1 },
    { name: "Startup 4", image: image2 },
    { name: "Startup 5", image: image1 },
    { name: "Startup 1", image: image1 },
    { name: "Startup 2", image: image2 },
    { name: "Startup 3", image: image1 },
    { name: "Startup 4", image: image2 },
    { name: "Startup 5", image: image1 },
  ];

  const smesPartners = [
    { name: "SME 1", image: image2 },
    { name: "SME 2", image: image1 },
    { name: "SME 3", image: image2 },
    { name: "SME 4", image: image1 },
    { name: "SME 5", image: image2 },
    { name: "SME 1", image: image2 },
    { name: "SME 2", image: image1 },
    { name: "SME 3", image: image2 },
    { name: "SME 4", image: image1 },
    { name: "SME 5", image: image2 },
  ];

  const coalitionPartners = [
    { name: "Coalition 1", image: image1 },
    { name: "Coalition 2", image: image2 },
    { name: "Coalition 3", image: image1 },
    { name: "Coalition 4", image: image2 },
    { name: "Coalition 5", image: image1 },
    { name: "Coalition 1", image: image1 },
    { name: "Coalition 2", image: image2 },
    { name: "Coalition 3", image: image1 },
    { name: "Coalition 4", image: image2 },
    { name: "Coalition 5", image: image1 },
  ];

  return (
    <div className="bg-gray-100">
      {/* Start Ups Section */}
      <PartnerSection
        title="Start Ups"
        description="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras gravida scelerisque felis eu fermentum. Nam ac eleifend nulla."
        partners={startupPartners}
      />

      {/* SMEs Section */}
      <PartnerSection
        title="SMEs"
        description="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras gravida scelerisque felis eu fermentum. Nam ac eleifend nulla."
        partners={smesPartners}
      />

      {/* Coalitions Section */}
      <PartnerSection
        title="Coalitions"
        description="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras gravida scelerisque felis eu fermentum. Nam ac eleifend nulla."
        partners={coalitionPartners}
      />
    </div>
  );
};

export default StartupsPage;
