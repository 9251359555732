import React from "react";

const SkeletonLoader = () => {
  return (
    <div className="bg-[#f3f6f3] text-gray-800 w-full">
      <main className="w-full max-w-[1440px] mx-auto py-12 px-4 md:px-8 grid grid-cols-1 lg:grid-cols-3 gap-8">
        {/* Skeleton for Featured Event */}
        <div className="lg:col-span-2">
          <div className="animate-pulse">
            <div className="h-10 bg-gray-300 rounded-md mb-4 w-1/2"></div>
            <div className="h-40 bg-gray-300 rounded-md mb-6"></div>
            <div className="h-6 bg-gray-300 rounded-md w-4/6"></div>
            <div className="h-4 bg-gray-300 rounded-md mt-4 w-3/4"></div>
          </div>
        </div>

        {/* Skeleton for Upcoming Events */}
        <aside className="lg:col-span-1">
          <div className="animate-pulse">
            <div className="h-10 bg-gray-300 rounded-md mb-4 w-1/2"></div>
            <div className="space-y-4">
              {[...Array(3)].map((_, index) => (
                <div key={index} className="h-20 bg-gray-300 rounded-md mb-4"></div>
              ))}
            </div>
          </div>
        </aside>
      </main>
    </div>
  );
};

export default SkeletonLoader;
