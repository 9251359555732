import React from 'react';

const MembershipCard = ({ img_src, title, description, isSelected, onClick }) => (
  <div
    onClick={onClick}
    className={`cursor-pointer p-6 rounded-lg shadow-md text-center flex flex-col items-center transition-transform duration-200 ${
      isSelected ? 'bg-blue-100 border-2 border-blue-500 transform scale-105' : 'bg-white'
    }`}
    style={{ maxWidth: '280px', width: '100%' }}
  >
    <img src={img_src} alt={title} className="h-24 w-24 mb-4 object-contain" />
    <h3 className="text-lg font-bold mb-2 font-big-shoulders">{title}</h3>
    <p className="text-gray-600 font-noto-sans text-sm sm:text-base">{description}</p>
  </div>
);

export default MembershipCard;
