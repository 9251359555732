import React from 'react';
import HeroSection from './HeroSection';
import HubSection from './HubSection';
import InterventionsSection from './InterventionsSection';
import PartnersSection from './PartnersSection';

const Home = () => {
  return (
    <main className="">
      <HeroSection />
      <HubSection />
      <InterventionsSection />
      <PartnersSection />
    </main>
  );
};

export default Home;
