import React from 'react';
import TeamMemberCard from './TeamMemberCard';
import image1 from '../../assets/intern.png';
import image2 from '../../assets/intern2.png';
import { useInView } from 'react-intersection-observer';

const teamMembers = [
  { name: 'Surname Name', role: 'Design Intern', image: image1 },
  { name: 'Surname Name', role: 'Design Intern', image: image2 },
  { name: 'Surname Name', role: 'Design Intern', image: image1 },
  { name: 'Surname Name', role: 'Design Intern', image: image2 },
  { name: 'Surname Name', role: 'Design Intern', image: image1 },
  { name: 'Surname Name', role: 'Design Intern', image: image2 },
  { name: 'Surname Name', role: 'Design Intern', image: image1 },
  { name: 'Surname Name', role: 'Design Intern', image: image2 },
];

const TeamSection = () => {
const { ref, inView } = useInView({ threshold: 0.2 });

return (
  <div
    ref={ref}
    className={`bg-blue-50 py-12 transform transition-all duration-1000 ${inView ? 'opacity-100 scale-100' : 'opacity-0 scale-90'}`}
  >
      <div className="container mx-auto px-6 space-y-8">
        <div>
          <h3 className="text-blue-600 text-2xl font-semibold font-big-shoulders">Our Team</h3>
          <p className="text-gray-700 mt-4 leading-relaxed font-noto-sans">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras gravida scelerisque felis eu fermentum. 
            Nam ac eleifend nulla. Proin elit ante, tristique at vulputate non, hendrerit sed orci. Aenean mattis, 
            orci ac feugiat tempus, urna mauris congue tortor, et pharetra diam massa sed nisi.
          </p>
        </div>

        <div className="grid grid-cols-2 md:grid-cols-4 gap-8 py-8">
          {teamMembers.map((member, index) => (
            <TeamMemberCard key={index} name={member.name} role={member.role} image={member.image} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default TeamSection;
