import React, { useState } from "react";
import { MdDashboard, MdMenu, MdClose } from "react-icons/md";
import { FaUsers, FaToolbox } from "react-icons/fa";
import { RiFridgeFill } from "react-icons/ri";
import { Link } from "react-router-dom";

const Sidebar = () => {
  const [activeLink, setActiveLink] = useState("Dashboard"); // Default active link
  const [isOpen, setIsOpen] = useState(false); // Sidebar visibility state

  const handleLinkClick = (linkName) => {
    setActiveLink(linkName); // Update active link
    setIsOpen(false); // Close sidebar after selection
  };

  const toggleSidebar = () => {
    setIsOpen((prev) => !prev); // Toggle sidebar open/close
  };

  return (
    <>
      {/* Sidebar Toggle Button for Mobile */}
      <button
        className="md:hidden fixed top-5 left-3 z-50 text-white bg-gray-800 p-2 rounded-full"
        onClick={toggleSidebar}
      >
        {isOpen ? <MdClose size={24} /> : <MdMenu size={24} />}
      </button>

      {/* Sidebar */}
      <div
        className={`fixed top-0 left-0 h-full w-64 bg-gray-800 text-gray-200 shadow-md transition-transform transform ${
          isOpen ? "translate-x-0" : "-translate-x-full"
        } md:translate-x-0 md:relative z-40`}
      >
        <div className="p-4">
          <h1 className="text-2xl font-bold text-white">Admin Dashboard</h1>
        </div>
        <nav className="p-4">
          <ul>
            {[
              { name: "Dashboard", icon: <MdDashboard />, path: "/admin-dashboard" },
              { name: "Clients", icon: <FaUsers />, path: "/admin-dashboard/users" },
              { name: "Cold Rooms", icon: <RiFridgeFill />, path: "/admin-dashboard/cold-rooms" },
              { name: "Bookings", icon: <RiFridgeFill />, path: "/admin-dashboard/bookings" },
              { name: "Settings", icon: <FaToolbox />, path: "/admin-dashboard/settings" },
            ].map((link) => (
              <li key={link.name} className="mb-4">
                <Link
                  to={link.path}
                  onClick={() => handleLinkClick(link.name)}
                  className={`flex items-center space-x-2 ${
                    activeLink === link.name ? "text-green-500" : "text-gray-200"
                  }`}
                >
                  {link.icon}
                  <span>{link.name}</span>
                </Link>
              </li>
            ))}
          </ul>
        </nav>
      </div>

      {/* Overlay for Mobile */}
      {isOpen && (
        <div
          className="fixed inset-0 bg-black opacity-50 z-30 md:hidden"
          onClick={toggleSidebar}
        />
      )}
    </>
  );
};

export default Sidebar;
