// DashboardLayout.js
import Sidebar from './Sidebar';
import Navbar from './Navbar';

function DashboardLayout({ children }) {
  return (
    <div className="flex flex-col lg:flex-row">
      <Sidebar />
      <div className="flex-1">
        <Navbar />
        <div className="p-4 lg:p-8">{children}</div>
      </div>
    </div>
  );
}

export default DashboardLayout;
