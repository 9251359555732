import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import API from './api';



// Async Thunk for submitting membership form
export const submitMembershipForm = createAsyncThunk(
  'membership/submitForm',
  async (formData, { rejectWithValue }) => {
    try {
      const config = {
        headers: { 'Content-Type': 'multipart/form-data' },
      };
      const response = await API.post('/membership', formData, config);
      // const response = await API.post('/membership', formData, config);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data || 'Something went wrong');
    }
  }
);

const membershipSlice = createSlice({
  name: 'membership',
  initialState: {
    loading: false,
    success: false,
    error: null,
  },
  reducers: {
    resetState: (state) => {
      state.loading = false;
      state.success = false;
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(submitMembershipForm.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(submitMembershipForm.fulfilled, (state) => {
        state.loading = false;
        state.success = true;
        state.error = null;
      })
      .addCase(submitMembershipForm.rejected, (state, action) => {
        state.loading = false;
        state.success = false;
        state.error = action.payload;
      });
  },
});

export const { resetState } = membershipSlice.actions;

export default membershipSlice.reducer;
