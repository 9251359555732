import React from 'react';
import { useInView } from 'react-intersection-observer';

const BuyInnovationsSection = () => {
  const { ref, inView } = useInView({ threshold: 0.2 });

  return (
    <section
      ref={ref}
      className={`py-20 bg-[#f3f6f3] px-6 md:px-12 lg:px-20 transform transition-opacity duration-1000 ${inView ? 'opacity-100 translate-y-0' : 'opacity-0 translate-y-10'}`}
    >
      <div className="max-w-8xl mx-auto text-center md:text-left">
        <h1 className="text-3xl md:text-3xl font-bold text-blue-600 mb-4 font-big-shoulders">
          Buy Our Innovations/Products
        </h1>
        <p className="text-md md:text-lg text-gray-700 mb-8 font-noto-sans">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras gravida scelerisque felis eu fermentum. Nam ac eleifend nulla,
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras gravida scelerisque felis eu fermentum. Nam ac eleifend nulla,Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras gravida scelerisque felis eu fermentum. Nam ac eleifend nulla.

        </p>
        <button className="bg-blue-500 text-white font-bold py-3 px-6 rounded-md">
          Visit our crowdfunding site
        </button>
      </div>
    </section>
  );
};

export default BuyInnovationsSection;
