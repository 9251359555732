import React, { useState } from "react";
import { useInView } from "react-intersection-observer";

const UpcomingEventItem = ({ title, image, description, index, onClick }) => {
  const { ref, inView } = useInView({ threshold: 0.1 });
  const [isExpanded, setIsExpanded] = useState(false);

  return (
    <div
      ref={ref}
      onClick={onClick}
      className={`bg-white rounded-md p-4 shadow-md flex flex-col sm:flex-row items-start transform transition-opacity duration-700 delay-${
        index * 100
      } cursor-pointer ${inView ? "opacity-100 translate-y-0" : "opacity-0 translate-y-5"}`}
    >
      {/* Image Section */}
      <img
        src={image}
        alt="Upcoming Event"
        className="w-full sm:w-20 h-20 object-cover rounded-md mb-4 sm:mb-0 sm:mr-4"
      />

      {/* Text Section */}
      <div className="flex-1">
        <h4 className="text-base sm:text-lg font-semibold text-blue-600 font-big-shoulders truncate">
          {title}
        </h4>
        <div
          className="text-sm sm:text-base text-gray-600 mt-2 font-noto-sans"
          dangerouslySetInnerHTML={{
            __html: isExpanded ? description : `${description.substring(0, 50)}...`,
          }}
        ></div>
        <button
          onClick={(e) => {
            e.stopPropagation();
            setIsExpanded(!isExpanded);
          }}
          className="text-blue-500 text-xs mt-1 underline"
        >
          {isExpanded ? "Show less" : "Read more"}
        </button>
      </div>
    </div>
  );
};

export default UpcomingEventItem;
