import React from "react";
import Slider from "react-slick";
import { useInView } from "react-intersection-observer";
import image1 from "../../assets/edited-1-1024x621.jpg";
import image2 from "../../assets/busin-meet.jpg";
import image3 from "../../assets/business2.jpg";

const images = [image1, image2, image3];

const HeroSection = () => {
  const { ref, inView } = useInView({ triggerOnce: true, threshold: 0.2 });

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
    rtl: false,
  };

  return (
    <section
      ref={ref}
      className={`relative h-screen transform transition-opacity duration-1000 ${
        inView ? "opacity-100 translate-y-0" : "opacity-0 translate-y-10"
      }`}
    >
      {/* Slider */}
      <Slider {...settings} className="h-full">
        {images.map((image, index) => (
          <div key={index} className="h-full">
            <div
              className="relative h-screen bg-cover bg-center"
              style={{ backgroundImage: `url(${image})` }}
            >
              {/* Overlay */}
              <div className="absolute inset-0 bg-black bg-opacity-50"></div>

              {/* Content */}
              <div className="relative z-10 flex flex-col justify-start items-start h-full text-white pl-4 sm:pl-8 lg:pl-12 pt-[30%] sm:pt-[25%] md:pt-[20%]">
                <h1 className="text-4xl sm:text-5xl md:text-6xl lg:text-7xl font-bold leading-tight text-blue-400 font-big-shoulders">
                  A Big Block of Text
                </h1>
                <p className="mt-6 text-lg sm:text-xl md:text-2xl max-w-2xl font-noto-sans leading-relaxed">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras
                  gravida scelerisque felis eu fermentum. Nam ac eleifend nulla.
                </p>

                {/* Buttons */}
                <div className="mt-8 flex flex-col sm:flex-row space-y-4 sm:space-y-0 sm:space-x-4">
                  <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-3 px-6 rounded text-lg">
                    Learn More
                  </button>
                  <button className="flex items-center space-x-2 hover:text-blue-400 text-lg">
                    <span>Watch Video</span>
                    <i className="fas fa-play"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
        ))}
      </Slider>
    </section>
  );
};

export default HeroSection;
