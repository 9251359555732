import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchTeamMembers,
  deleteTeamMember,
} from "../../redux/teamSlice";
import Sidebar from "./Sidebar";
import TeamMemberForm from "./TeamMemberForm";
import { toast } from "react-toastify";

const AdminTeamsPage = () => {
  const dispatch = useDispatch();
  const { members, loading, error } = useSelector((state) => state.team);
  const [editMode, setEditMode] = useState(false);
  const [currentMember, setCurrentMember] = useState(null);
  const [isPopupOpen, setIsPopupOpen] = useState(false);

  useEffect(() => {
    dispatch(fetchTeamMembers());
  }, [dispatch]);

  const handleEdit = (member) => {
    setEditMode(true);
    setCurrentMember(member);
    setIsPopupOpen(true);
  };

  const handleDelete = (id) => {
    dispatch(deleteTeamMember(id))
      .unwrap()
      .then(() => toast.success("Team member deleted successfully"))
      .catch(() => toast.error("Failed to delete team member"));
  };

  const handleAddMember = () => {
    setEditMode(false);
    setCurrentMember(null);
    setIsPopupOpen(true);
  };

  return (
    <div className="flex">
      {/* Sidebar */}
      <Sidebar />
      {/* Main Content */}
      <div className="flex-1 p-8">
        <h1 className="text-2xl font-bold mb-6">Team Management</h1>

        <button
          onClick={handleAddMember}
          className="bg-green-500 text-white px-4 py-2 rounded mb-6"
        >
          Add Team Member
        </button>

        {/* Display Team Members */}
        {loading && <p>Loading team members...</p>}
        {error && <p className="text-red-500">{error}</p>}
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6">
          {members.map((member) => (
            <div
              key={member.id}
              className="bg-white p-4 rounded-lg shadow flex flex-col items-center"
            >
              <img
                src={member.imageUrl}
                alt={member.name}
                className="w-24 h-24 object-cover rounded-full mb-4"
              />
              <h2 className="text-lg font-semibold">{member.name}</h2>
              <p className="text-sm text-gray-600">{member.title}</p>
              <div className="mt-4 flex gap-2">
                <button
                  onClick={() => handleEdit(member)}
                  className="bg-blue-500 text-white px-4 py-2 rounded-md"
                >
                  Edit
                </button>
                <button
                  onClick={() => handleDelete(member.id)}
                  className="bg-red-500 text-white px-4 py-2 rounded-md"
                >
                  Delete
                </button>
              </div>
            </div>
          ))}
        </div>
      </div>

      {/* Popup Form */}
      {isPopupOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
          <div className="bg-white p-6 rounded-lg shadow-lg w-96">
            <button
              className="text-gray-500 hover:text-gray-800 absolute top-4 right-4"
              onClick={() => setIsPopupOpen(false)}
            >
              &times;
            </button>
            <TeamMemberForm
              editMode={editMode}
              existingMember={currentMember}
              onClose={() => {
                setIsPopupOpen(false);
                setEditMode(false);
                setCurrentMember(null);
              }}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default AdminTeamsPage;
