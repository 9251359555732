// Navbar.js
import { AiOutlineBell, AiOutlineSetting, AiOutlineMessage } from "react-icons/ai";

function Navbar() {
  return (
    <div className="flex justify-between items-center bg-white h-16 p-4 shadow-md">
      <input
        type="text"
        placeholder="Type to search..."
        className="border p-2 rounded-md w-full lg:w-1/3"
      />
      <div className="flex items-center gap-4">
        <AiOutlineSetting className="text-xl hidden md:block" />
        <AiOutlineBell className="text-xl hidden md:block" />
        <AiOutlineMessage className="text-xl hidden md:block" />
        <div className="flex items-center gap-2">
          <span className="hidden md:block">Ubuntu</span>
          <img
            src="https://via.placeholder.com/40"
            alt="User"
            className="rounded-full w-10 h-10"
          />
        </div>
      </div>
    </div>
  );
}

export default Navbar;
