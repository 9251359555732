import React from 'react';

const TeamMemberCard = ({ name, role, image }) => {
  return (
    <div className="text-center">
      <div className="w-24 h-24 md:w-32 md:h-32 bg-gray-200 rounded-full mx-auto mb-4">
        <img src={image} alt={name} />
      </div>
      <h4 className="text-lg font-semibold font-big-shoulders">{name}</h4>
      <p className="text-gray-500 font-noto-sans">{role}</p>
    </div>
  );
};

export default TeamMemberCard;
