import React from "react";
import { useInView } from "react-intersection-observer";

const BoardMemberCard = ({ name, role, image, index }) => {
  const { ref, inView } = useInView({ threshold: 0.1 });

  return (
    <div
      ref={ref}
      className={`text-center transition-opacity duration-500 delay-${index * 100} ${
        inView ? "opacity-100 translate-y-0" : "opacity-0 translate-y-5"
      }`}
    >
      <div className="w-24 h-24 md:w-32 md:h-32 bg-gray-200 rounded-full mx-auto mb-4">
        <img
          src={image}
          alt={name}
          className="object-cover w-full h-full rounded-full"
        />
      </div>
      <h4 className="text-lg font-semibold font-big-shoulders">{name}</h4>
      <p className="text-gray-500 font-noto-sans">{role}</p>
    </div>
  );
};

export default BoardMemberCard;
