import React from "react";
import DonateSection from "./DonateSection";
import HireUsSection from "./HireUsSection";
import BuyInnovationsSection from "./BuyInnovationsSection";
import image1 from "../../assets/events.png";

const SupportUsPage = () => {
  return (
    <div className="bg-[#f3f6f3] text-gray-800 w-full">
      <section className="py-14 bg-[#E8F8FF] px-6 lg:px-12">
        <div className="max-w-12xl mx-auto">
          {/* Section Title */}
          <h1 className="text-3xl md:text-4xl lg:text-5xl font-bold text-green-600 mb-8 py-4 font-big-shoulders text-left">
            Support Us
          </h1>

          {/* Grid Layout */}
          <div className="grid grid-cols-1 md:grid-cols-2 gap-8 pt-6">
            {/* Left: Image */}
            <img
              src={image1}
              alt="Support Us"
              className="rounded-lg object-cover w-full h-72 md:h-96 lg:h-[500px]"
            />

            {/* Right: Text Content */}
            <div className="flex flex-col justify-start">
              <h2 className="text-xl md:text-2xl lg:text-3xl font-bold text-blue-600 font-big-shoulders text-left">
                How you can support Us
              </h2>
              <p className="text-sm md:text-base lg:text-lg text-gray-700 mt-4 font-noto-sans leading-relaxed text-left">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras gravida
                scelerisque felis eu fermentum. Nam ac eleifend nulla. Proin elit ante,
                tristique at vulputate non, hendrerit sed orci. Aenean mattis, orci ac
                feugiat tempus, urna mauris congue tortor, et pharetra diam massa sed
                nisi. Mauris fringilla diam aliquet semper laoreet. Quisque tincidunt
                nisi faucibus purus ornare, quis lobortis urna sodales.
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras gravida
                scelerisque felis eu fermentum. Nam ac eleifend nulla. Proin elit ante,
                tristique at vulputate non, hendrerit sed orci. Aenean mattis, orci ac
                feugiat tempus, urna mauris congue tortor, et pharetra diam massa sed
                nisi. Mauris fringilla diam aliquet semper laoreet. Quisque tincidunt
                nisi faucibus purus ornare, quis lobortis urna sodales.
              </p>
              <p className="text-sm md:text-base lg:text-lg text-gray-700 mt-4 font-noto-sans leading-relaxed text-left">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras gravida
                scelerisque felis eu fermentum. Nam ac eleifend nulla. Proin elit ante,
                tristique at vulputate non, hendrerit sed orci. Aenean mattis, orci ac
                feugiat tempus, urna mauris congue tortor, et pharetra diam massa sed
                nisi. Mauris fringilla diam aliquet semper laoreet. Quisque tincidunt
                nisi faucibus purus ornare, quis lobortis urna sodales.
              </p>
            </div>
          </div>
        </div>
      </section>
      <DonateSection />
      <HireUsSection />
      <BuyInnovationsSection />
    </div>
  );
};

export default SupportUsPage;
