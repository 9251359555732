import React, { useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { fetchPartners } from "../../redux/partnerSlice";

const PartnersSection = () => {
  const dispatch = useDispatch();
  const { partners, loading, error } = useSelector((state) => state.partners || {});
  const carouselRef = useRef(null);

  useEffect(() => {
    // Fetch partners on component mount
    dispatch(fetchPartners());
  }, [dispatch]);

  useEffect(() => {
    // Continuous sliding effect
    const carousel = carouselRef.current;
    if (carousel) {
      let scrollAmount = 0;
      const scrollStep = 1;
      const interval = setInterval(() => {
        scrollAmount += scrollStep;
        if (scrollAmount >= carousel.scrollWidth / 2) {
          scrollAmount = 0;
        }
        carousel.scrollLeft = scrollAmount;
      }, 20);
      return () => clearInterval(interval);
    }
  }, [partners]);

  if (loading) return <p>Loading...</p>;
  if (error) return <p className="text-red-500">Error: {error}</p>;

  return (
    <section className="py-12 bg-gray-100 px-6 md:px-12 lg:px-20">
      <div className="container mx-auto">
        {/* Title */}
        <h2 className="text-2xl md:text-3xl font-bold text-blue-600 mb-10 font-big-shoulders text-left">
          Our Partners
        </h2>

        {/* Carousel Wrapper */}
        <div
          ref={carouselRef}
          className="flex overflow-hidden w-full whitespace-nowrap"
        >
          {/* Partner Logos */}
          {partners &&
            partners.map((partner, index) => (
              <div
                key={index}
                className="flex justify-center items-center px-4 min-w-[15%] md:min-w-[10%]"
              >
                <img
                  src={partner.imageUrl || "https://via.placeholder.com/100"}
                  alt={partner.name || `Partner ${index + 1}`}
                  className="h-16 w-16 md:h-24 md:w-24 object-contain rounded-md shadow-md"
                />
              </div>
            ))}

          {/* Duplicate the partners for seamless looping */}
          {partners &&
            partners.map((partner, index) => (
              <div
                key={`duplicate-${index}`}
                className="flex justify-center items-center px-4 min-w-[15%] md:min-w-[10%]"
              >
                <img
                  src={partner.imageUrl || "https://via.placeholder.com/100"}
                  alt={partner.name || `Partner ${index + 1}`}
                  className="h-16 w-16 md:h-24 md:w-24 object-contain rounded-md shadow-md"
                />
              </div>
            ))}
        </div>
      </div>
    </section>
  );
};

export default PartnersSection;
