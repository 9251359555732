import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { submitHireRequest, resetState } from "../../redux/hireSlice";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import travel from "../../assets/travel.png";
import art from "../../assets/art.png";
import urban from "../../assets/urban.png";
import mini from "../../assets/mini.png";
import interns from "../../assets/interns.png";
import construction from "../../assets/construction.png";

const HireUsSection = () => {
  const dispatch = useDispatch();
  const { loading, success, error } = useSelector((state) => state.hire);

  const [form, setForm] = useState({
    category: "",
    projectName: "",
    scope: "",
    description: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setForm({ ...form, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // Validate required fields
    const { category, projectName, scope, description } = form;
    if (!category || !projectName || !scope || !description) {
      toast.error("All fields are required.");
      return;
    }

    // Dispatch the form data
    dispatch(submitHireRequest(form));
  };

  useEffect(() => {
    if (success) {
      toast.success("Hire request submitted successfully!");
      dispatch(resetState());
      setForm({
        category: "",
        projectName: "",
        scope: "",
        description: "",
      });
    }
    if (error) {
      toast.error(error);
    }
  }, [success, error, dispatch]);

  return (
    <section className="py-20 bg-[#f3f6f3] px-6 lg:px-20 transform transition-transform duration-1000">
      <div className="max-w-7xl mx-auto grid grid-cols-1 lg:grid-cols-2 gap-16 items-start">
        {/* Left Side: Form */}
        <div>
          <h2 className="text-3xl md:text-4xl font-bold text-green-600 mb-6 font-big-shoulders text-left">
            Hire Us
          </h2>
          <p className="text-gray-700 text-base md:text-lg mb-8 font-noto-sans leading-relaxed text-left">
            Support by hiring us for a professional project. Fill out the form below to get started.
          </p>
          <form onSubmit={handleSubmit} className="space-y-6">
            <select
              name="category"
              value={form.category}
              onChange={handleChange}
              className="w-full p-3 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
            >
              <option value="">Choose from list</option>
              <option value="Company A">Company A</option>
              <option value="Company B">Company B</option>
            </select>
            <input
              type="text"
              name="projectName"
              value={form.projectName}
              onChange={handleChange}
              placeholder="Project Name"
              className="w-full p-3 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
            <input
              type="text"
              name="scope"
              value={form.scope}
              onChange={handleChange}
              placeholder="Scope"
              className="w-full p-3 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
            <textarea
              name="description"
              value={form.description}
              onChange={handleChange}
              placeholder="Description"
              className="w-full p-3 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 h-32"
            />
            <button
              type="submit"
              disabled={loading}
              className="bg-blue-500 text-white font-bold py-3 rounded-md mt-4 px-10 hover:bg-blue-600 focus:outline-none"
            >
              {loading ? "Submitting..." : "Submit"}
            </button>
          </form>
        </div>

        {/* Right Side: Partners */}
        <div className="grid gap-6 items-center justify-center">
          <div className="grid gap-6">
            {[travel, art, urban, mini, interns, construction].map(
              (partner, index) => (
                <div
                  key={index}
                  className="p-2 border border-gray-300 bg-white rounded-lg shadow-sm flex items-center justify-center"
                >
                  <img
                    src={partner}
                    alt={`Partner ${index + 1}`}
                    className="h-16 w-auto object-contain"
                  />
                </div>
              )
            )}
          </div>
        </div>
      </div>
    </section>
  );
};

export default HireUsSection;
