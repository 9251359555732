import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchInterventions,
  deleteIntervention,
} from "../../../redux/interventionSlice";
import { toast } from "react-toastify";
import PostInterventionForm from "./PostInterventionForm";
import Sidebar from "../Sidebar";

const AdminIntervention = () => {
  const dispatch = useDispatch();
  const { interventions, loading, error } = useSelector(
    (state) => state.interventions
  );

  const [editingIntervention, setEditingIntervention] = useState(null);
  const [showCreateForm, setShowCreateForm] = useState(false);
  const [selectedIntervention, setSelectedIntervention] = useState(null); // Modal state for viewing details

  useEffect(() => {
    dispatch(fetchInterventions());
  }, [dispatch]);

  const handleDelete = (id) => {
    if (window.confirm("Are you sure you want to delete this intervention?")) {
      dispatch(deleteIntervention(id))
        .unwrap()
        .then(() => toast.success("Intervention deleted successfully!"))
        .catch(() => toast.error("Failed to delete intervention"));
    }
  };

  const handleEdit = (intervention) => {
    setEditingIntervention(intervention);
    setShowCreateForm(false);
  };

  const handleCloseEdit = () => {
    setEditingIntervention(null);
  };

  const handleShowCreateForm = () => {
    setShowCreateForm(true);
    setEditingIntervention(null);
  };

  const truncateText = (text, maxLength) => {
    if (text.length <= maxLength) return text;
    return text.slice(0, maxLength) + "...";
  };

  const openModal = (intervention) => {
    setSelectedIntervention(intervention);
  };

  const closeModal = () => {
    setSelectedIntervention(null);
  };

  return (
    <div className="flex">
      {/* Sidebar */}
      <Sidebar />

      {/* Main Content */}
      <div className="flex-1 p-8 bg-gray-100">
        <h1 className="text-2xl font-bold mb-6">Intervention Management</h1>

        {/* Create Intervention Form */}
        {showCreateForm && (
          <div className="mb-8 bg-white p-4 rounded shadow">
            <h2 className="text-xl font-bold mb-4">Create New Intervention</h2>
            <PostInterventionForm />
            <button
              className="text-red-500 mt-4"
              onClick={() => setShowCreateForm(false)}
            >
              Cancel
            </button>
          </div>
        )}

        {/* Edit Intervention Form */}
        {editingIntervention && (
          <div className="mb-8 bg-white p-4 rounded shadow">
            <h2 className="text-xl font-bold mb-4">Edit Intervention</h2>
            <PostInterventionForm
              editMode
              existingIntervention={editingIntervention}
              onClose={handleCloseEdit}
            />
          </div>
        )}

        {/* Intervention List */}
        {loading ? (
          <p>Loading interventions...</p>
        ) : error ? (
          <p className="text-red-500">{error}</p>
        ) : interventions.length > 0 ? (
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
            {interventions.map((intervention) => (
              <div
                key={intervention.id}
                className="bg-white rounded shadow p-4 flex flex-col justify-between"
              >
                <div>
                  <img
                    src={intervention.imageUrl}
                    alt={intervention.title}
                    className="w-full h-40 object-cover rounded mb-4"
                  />
                  <h3 className="text-lg font-bold">{intervention.title}</h3>
                  <p className="text-sm text-gray-600">
                    {truncateText(intervention.description, 100)}{" "}
                    <button
                      onClick={() => openModal(intervention)}
                      className="text-blue-500 underline"
                    >
                      Read more
                    </button>
                  </p>
                </div>
                <div className="mt-4 flex justify-between">
                  <button
                    onClick={() => handleEdit(intervention)}
                    className="bg-blue-500 text-white px-4 py-2 rounded"
                  >
                    Edit
                  </button>
                  <button
                    onClick={() => handleDelete(intervention.id)}
                    className="bg-red-500 text-white px-4 py-2 rounded"
                  >
                    Delete
                  </button>
                </div>
              </div>
            ))}
          </div>
        ) : (
          <p>No interventions found.</p>
        )}

        {/* Button to Toggle Create Form */}
        {!showCreateForm && !editingIntervention && (
          <button
            onClick={handleShowCreateForm}
            className="bg-green-500 text-white px-4 py-2 rounded mt-4"
          >
            Create New Intervention
          </button>
        )}
      </div>

      {/* Modal for Viewing Full Intervention Details */}
      {selectedIntervention && (
        <div
          className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center"
          onClick={closeModal}
        >
          <div
            className="bg-white p-6 rounded shadow max-w-lg w-full"
            onClick={(e) => e.stopPropagation()}
          >
            <h2 className="text-xl font-bold mb-4">
              {selectedIntervention.title}
            </h2>
            <img
              src={selectedIntervention.imageUrl}
              alt={selectedIntervention.title}
              className="w-full h-40 object-cover rounded mb-4"
            />
            <p className="text-gray-700">{selectedIntervention.description}</p>
            <button
              className="mt-4 text-red-500"
              onClick={closeModal}
            >
              Close
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default AdminIntervention;
