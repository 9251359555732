import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import API from "./api";

// Fetch all hubs
export const fetchHubs = createAsyncThunk("hubs/fetchHubs", async (_, { rejectWithValue }) => {
  try {
    const response = await API.get("/hubs");
    return response.data;
  } catch (error) {
    return rejectWithValue(error.response?.data?.message || "Failed to fetch hubs.");
  }
});

// Fetch hubs by ID
export const fetchHubById = createAsyncThunk("hubs/fetchHubById", async (id, { rejectWithValue }) => {
  try {
    const response = await API.get(`/hubs/${id}`);
    return response.data;
  } catch (error) {
    return rejectWithValue(error.response?.data?.message || "Failed to fetch hub.");
  }
});

// Create an hub
export const createHub = createAsyncThunk(
    "hubs/createHub",
    async (hubData, { rejectWithValue }) => {
      try {
        const response = await API.post("/hubs", hubData, {
          headers: { "Content-Type": "multipart/form-data" },
        });
        return response.data;
      } catch (error) {
        return rejectWithValue(error.response?.data?.message || "Failed to create a hub.");
      }
    }
  );

// Update a hub
export const updateHub = createAsyncThunk(
  "hubs/updateHub",
  async ({ id, hubData }, { rejectWithValue }) => {
    try {
      const response = await API.put(`/hubs/${id}`, hubData, {
        headers: { "Content-Type": "multipart/form-data" },
      });
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data?.message || "Failed to update a hub.");
    }
  }
);

// Delete a hub
export const deleteHub = createAsyncThunk("hubs/deleteHub", async (id, { rejectWithValue }) => {
  try {
    await API.delete(`/hubs/${id}`);
    return id;
  } catch (error) {
    return rejectWithValue(error.response?.data?.message || "Failed to delete a hub.");
  }
});

const hubSlice = createSlice({
  name: "hubs",
  initialState: {
    hubs: [],
    selectedHub: null,
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      // Fetch all hubs
      .addCase(fetchHubs.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchHubs.fulfilled, (state, action) => {
        state.loading = false;
        state.hubs = action.payload;
      })
      .addCase(fetchHubs.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })

      // Fetch hub by ID
      .addCase(fetchHubById.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchHubById.fulfilled, (state, action) => {
        state.loading = false;
        state.selectedHub = action.payload;
      })
      .addCase(fetchHubById.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })

      // Create a hub
      .addCase(createHub.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(createHub.fulfilled, (state, action) => {
        state.loading = false;
        state.hubs = [action.payload, ...state.hubs];
      })
      .addCase(createHub.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })

      // Update hub
      .addCase(updateHub.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(updateHub.fulfilled, (state, action) => {
        state.loading = false;
        state.hubs = state.hubs.map((hub) =>
          hub.id === action.payload.id ? action.payload : hub
        );
      })
      .addCase(updateHub.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })

      // Delete hub
      .addCase(deleteHub.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(deleteHub.fulfilled, (state, action) => {
        state.loading = false;
        state.hubs = state.hubs.filter((hub) => hub.id !== action.payload);
      })
      .addCase(deleteHub.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export default hubSlice.reducer;
