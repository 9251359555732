import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { addTeamMember, updateTeamMember } from "../../redux/teamSlice";
import { toast } from "react-toastify";

const TeamMemberForm = ({ editMode, existingMember, onClose }) => {
  const dispatch = useDispatch();
  const [form, setForm] = useState({
    name: "",
    title: "",
    image: null,
    imageUrl: "",
  });

  useEffect(() => {
    if (editMode && existingMember) {
      setForm({
        name: existingMember.name || "",
        title: existingMember.title || "",
        image: null,
        imageUrl: existingMember.imageUrl || "",
      });
    }
  }, [editMode, existingMember]);

  const handleChange = (e) => {
    const { name, value, files } = e.target;
    setForm({
      ...form,
      [name]: files ? files[0] : value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("name", form.name);
    formData.append("title", form.title);
  
    if (form.image) {
      formData.append("image", form.image);
    } else if (editMode && form.imageUrl) {
      formData.append("imageUrl", form.imageUrl);
    }
  
    if (editMode) {
      dispatch(updateTeamMember({ id: existingMember.id, teamData: formData }))
        .unwrap()
        .then(() => {
          toast.success("Team member updated successfully");
          onClose();
        })
        .catch(() => toast.error("Failed to update team member"));
    } else {
      dispatch(addTeamMember(formData))
        .unwrap()
        .then(() => {
          toast.success("Team member added successfully");
          onClose();
        })
        .catch(() => toast.error("Failed to add team member"));
    }
  };  

  return (
    <form className="space-y-4" onSubmit={handleSubmit}>
      <input
        type="text"
        name="name"
        placeholder="Name"
        value={form.name}
        onChange={handleChange}
        className="w-full p-3 border rounded-md"
      />
      <input
        type="text"
        name="title"
        placeholder="Title"
        value={form.title}
        onChange={handleChange}
        className="w-full p-3 border rounded-md"
      />
      <input
        type="file"
        name="image"
        accept="image/*"
        onChange={handleChange}
        className="w-full p-3 border rounded-md"
      />
      {editMode && form.imageUrl && (
        <div className="flex items-center space-x-4">
          <img
            src={form.imageUrl}
            alt="Current"
            className="w-16 h-16 object-cover rounded-md"
          />
          <span className="text-sm text-gray-500">Current Image</span>
        </div>
      )}
      <button type="submit" className="bg-green-500 text-white py-2 px-4 rounded">
        {editMode ? "Update Team Member" : "Add Team Member"}
      </button>
    </form>
  );
};

export default TeamMemberForm;
