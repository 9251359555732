import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { createPartner, updatePartner } from "../../../redux/partnerSlice";
import { toast } from "react-toastify";

const PartnerForm = ({ editMode, existingPartner, onClose }) => {
  const dispatch = useDispatch();
  const [form, setForm] = useState({
    names: "",
    image: null,
    imageUrl: "",
  });

  useEffect(() => {
    if (editMode && existingPartner) {
      setForm({
        names: existingPartner.names || "",
        image: null,
        imageUrl: existingPartner.imageUrl || "",
      });
    }
  }, [editMode, existingPartner]);

  const handleChange = (e) => {
    const { name, value, files } = e.target;
    setForm({
      ...form,
      [name]: files ? files[0] : value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!form.names.trim()) {
      toast.error("Partner name is required");
      return;
    }

    const formData = new FormData();
    formData.append("names", form.names);

    if (form.image) {
      formData.append("image", form.image);
    } else if (editMode && form.imageUrl) {
      formData.append("imageUrl", form.imageUrl);
    }

    if (editMode) {
      dispatch(updatePartner({ id: existingPartner.id, partnerData: formData }))
        .unwrap()
        .then(() => {
          toast.success("Partner updated successfully");
          onClose();
        })
        .catch(() => toast.error("Failed to update Partner"));
    } else {
      dispatch(createPartner(formData))
        .unwrap()
        .then(() => {
          toast.success("Partner added successfully");
          onClose();
        })
        .catch(() => toast.error("Failed to add Partner"));
    }
  };

  return (
    <form className="space-y-4" onSubmit={handleSubmit}>
      <input
        type="text"
        name="names"
        placeholder="Name"
        value={form.names}
        onChange={handleChange}
        className="w-full p-3 border rounded-md"
      />
      <input
        type="file"
        name="image"
        accept="image/*"
        onChange={handleChange}
        className="w-full p-3 border rounded-md"
      />
      {editMode && form.imageUrl && (
        <div className="flex items-center space-x-4">
          <img
            src={form.imageUrl}
            alt="Current"
            className="w-16 h-16 object-cover rounded-md"
          />
          <span className="text-sm text-gray-500">Current Image</span>
        </div>
      )}
      <button type="submit" className="bg-green-500 text-white py-2 px-4 rounded">
        {editMode ? "Update Partner" : "Add Partner"}
      </button>
    </form>
  );
};

export default PartnerForm;
