import './App.css';
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
import About from './Component/about';
import Footer from './Component/Footer';
import Header from './Component/Header';
import Home from './Component/Home';
import TopHeader from './Component/TopHeader';
import EventsPage from './Component/EventPage/EventsPage';
import BecomeMemberPage from './Component/Members/BecomeMemberPage';
import SupportUsPage from './Component/support/SupportUsPage';
import AdminDashboard from './Component/admin';
import Dashboard from './Component/user/Dashboard';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import LoginForm from './page/LoginForm';
import AdminEventsPage from './Component/user/AdminEventsPage';
import AdminTeamsPage from './Component/user/AdminTeamsPage';
import AdminIntervention from './Component/user/intervention/intervention';
import AdminHubsPage from './Component/user/hubs/AdminHubsPage';
import AdminPartnersPage from './Component/user/partner/AdminPartnersPage';
import StartupsPage from './Component/Startup/Startup';
import HubsPage from './page/HubsPage';
import ContactForm from './Component/ContactForm';

// LayoutWrapper Component
const LayoutWrapper = ({ children }) => {
  const location = useLocation();

  // Hide Header, TopHeader, and Footer on specific routes
  const noLayoutRoutes = ['/dashboard', '/login'];

  const hideLayout = noLayoutRoutes.includes(location.pathname);

  return (
    <>
      {!hideLayout && <TopHeader />}
      {!hideLayout && <Header />}
      <ToastContainer />
      {children}
      {!hideLayout && <Footer />}
    </>
  );
};

function App() {
  return (
    <Router >
      <LayoutWrapper>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/events" element={<EventsPage />} />
          <Route path="/membership" element={<BecomeMemberPage />} />
          <Route path="/support" element={<SupportUsPage />} />
          <Route path="/admin" element={<AdminDashboard />} />
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/login" element={<LoginForm />} />
          <Route path="/admin-events" element={<AdminEventsPage />} />
          <Route path="/admin-teams" element={<AdminTeamsPage />} />
          <Route path="/admin-intervention" element={<AdminIntervention />} />
          <Route path="/admin-hubs" element={<AdminHubsPage />} />
          <Route path="/admin-partners" element={<AdminPartnersPage />} />
          <Route path="/ventures" element={<StartupsPage />} />
          <Route path="/hubs" element={<HubsPage />} />
          <Route path="/contact" element={<ContactForm />} />

        </Routes>
      </LayoutWrapper>
    </Router>
  );
}

export default App;
