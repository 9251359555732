import React, { useState, useEffect } from "react";
import { useInView } from "react-intersection-observer";
import { useSelector, useDispatch } from "react-redux";
import { fetchInterventions } from "../../redux/interventionSlice";

const Modal = ({ intervention, onClose }) => {
  if (!intervention) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
      <div className="bg-white rounded-lg p-6 md:p-8 w-11/12 max-w-5xl">
        <h2 className="text-2xl md:text-3xl font-bold mb-4 text-center">{intervention.title}</h2>
        <img
          src={intervention.imageUrl}
          alt={intervention.title}
          className="w-full h-64 md:h-80 object-cover mb-6 rounded-lg"
        />
        <p className="text-gray-800 text-sm md:text-lg leading-relaxed">
          {intervention.description}
        </p>
        <div className="mt-4 text-right">
          <button
            onClick={onClose}
            className="bg-green-600 text-white py-2 px-4 rounded hover:bg-green-700"
          >
            Close
          </button>
        </div>
      </div>
    </div>
  );
};


const InterventionsSection = () => {
  const { ref, inView } = useInView({ threshold: 0.2 });
  const [currentIndex, setCurrentIndex] = useState(0);
  const [selectedIntervention, setSelectedIntervention] = useState(null);
  const dispatch = useDispatch();
  const { interventions, loading, error } = useSelector((state) => state.interventions);

  useEffect(() => {
    dispatch(fetchInterventions());
  }, [dispatch]);

  const handleNext = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 2) % interventions.length);
  };

  const handlePrev = () => {
    setCurrentIndex((prevIndex) => (prevIndex - 2 + interventions.length) % interventions.length);
  };

  const displayedInterventions = [
    interventions[currentIndex],
    interventions[(currentIndex + 1) % interventions.length],
  ];

  if (loading) {
    return <div className="text-center text-green-600">Loading interventions...</div>;
  }

  if (error) {
    return <div className="text-center text-red-600">Error: {error}</div>;
  }

  return (
    <section
      ref={ref}
      className={`py-20 bg-[#f3f6f3] px-6 md:px-12 lg:px-20 transform transition-transform duration-1000 ${
        inView ? "opacity-100 translate-y-0" : "opacity-0 translate-y-10"
      }`}
    >
      <div className="container mx-auto">
        <h2 className="text-3xl md:text-4xl font-bold text-green-600 mb-8 font-big-shoulders text-left">
          Explore Our Interventions
        </h2>

        <div className="relative flex items-center justify-center">
          {/* Previous Button */}
          <button
            onClick={handlePrev}
            className="absolute left-4 text-blue-500 font-bold hover:text-blue-700 text-3xl md:text-4xl z-10"
          >
            &#8249;
          </button>

          {/* Interventions Grid */}
          <div className="grid grid-cols-1 md:grid-cols-2 gap-8 w-full max-w-5xl">
            {interventions.map((intervention, index) => (
              <div
                key={index}
                className="bg-white shadow-lg p-6 md:p-8 rounded-lg transform transition-transform duration-500 hover:scale-105"
              >
                <h3 className="text-2xl md:text-3xl font-bold mb-4 font-big-shoulders text-center">
                  {intervention.title}
                </h3>
                <img
                  src={intervention.imageUrl}
                  alt={intervention.title}
                  className="w-full h-48 md:h-64 object-cover mb-6 rounded-lg"
                />
                <p className="text-gray-800 font-noto-sans text-sm md:text-lg leading-relaxed">
                  {intervention.description.substring(0, 100)}...
                  <button
                    onClick={() => setSelectedIntervention(intervention)}
                    className="text-blue-500 font-bold ml-2"
                  >
                    Read More
                  </button>
                </p>
              </div>
            ))}
          </div>

          {/* Next Button */}
          <button
            onClick={handleNext}
            className="absolute right-4 text-blue-500 font-bold hover:text-blue-700 text-3xl md:text-4xl z-10"
          >
            &#8250;
          </button>
        </div>
      </div>

      {/* Modal */}
      <Modal
        intervention={selectedIntervention}
        onClose={() => setSelectedIntervention(null)}
      />
    </section>
  );
};

export default InterventionsSection;
