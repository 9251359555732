import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import DashboardLayout from "./DashboardLayout";
import { fetchEvents } from "../../redux/eventSlice";
import { fetchHireRequests } from "../../redux/hireSlice";

function Dashboard() {
  const dispatch = useDispatch();

  // Fetch data from Redux store
  const { events, loading: eventsLoading } = useSelector((state) => state.events);
  const { hireRequests, loading: hireLoading } = useSelector((state) => state.hire);

  useEffect(() => {
    dispatch(fetchEvents()); // Fetch all events
    dispatch(fetchHireRequests()); // Fetch all hire requests
  }, [dispatch]);

  const totalUsers = 1234; // Replace with actual user count if available from another slice
  const totalRevenue = 12345; // Replace with dynamic calculation if available

  return (
    <DashboardLayout>
      <h1 className="text-2xl font-semibold mb-4">Dashboard</h1>

      {/* Statistics Section */}
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4 mb-8">
        <div className="bg-blue-500 text-white p-4 rounded shadow-md">
          <h2 className="text-xl font-bold">Users</h2>
          <p className="text-2xl">{totalUsers}</p>
        </div>
        <div className="bg-green-500 text-white p-4 rounded shadow-md">
          <h2 className="text-xl font-bold">Events</h2>
          <p className="text-2xl">
            {eventsLoading ? "Loading..." : events.length}
          </p>
        </div>
        <div className="bg-yellow-500 text-white p-4 rounded shadow-md">
          <h2 className="text-xl font-bold">Bookings</h2>
          <p className="text-2xl">
            {hireLoading ? "Loading..." : hireRequests.length}
          </p>
        </div>
        <div className="bg-red-500 text-white p-4 rounded shadow-md">
          <h2 className="text-xl font-bold">Revenue</h2>
          <p className="text-2xl">${totalRevenue}</p>
        </div>
      </div>

      {/* Buttons Section */}
      <h2 className="text-xl font-bold mb-4">Quick Actions</h2>
      <div className="grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-4 gap-4">
        <button className="bg-blue-500 text-white p-2 rounded">Add Event</button>
        <button className="bg-green-500 text-white p-2 rounded">View Events</button>
        <button className="bg-gray-800 text-white p-2 rounded">Settings</button>
        <button className="bg-yellow-500 text-white p-2 rounded">Reports</button>
      </div>
    </DashboardLayout>
  );
}

export default Dashboard;
