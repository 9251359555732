// import React from "react";
import { Routes, Route } from 'react-router-dom';
import Sidebar from "./Sidebar";
// import Navbar from "./Navbar.js";
// import TableWithDropdown from './ColdRoom';
import Dashboard from "./Dashboard";
// import Profile from "./Profiles";
// import Bookings from "./booking/";
// import ColdRoomForm from "./ColdRoomForm";

const AdminDashboard = () => {
  return (
    <div className="flex min-h-screen">
      <Sidebar />
      <div className="flex-1 bg-gray-100">
        {/* <Navbar /> */}
        <div className="flex-1 bg-gray-100 p-4">
          {/* Main admin dashboard content */}
          <Routes>
            <Route path="/" element={<Dashboard />} />
            {/* <Route path="/cold-rooms" element={<TableWithDropdown />} />
            <Route path="/users" element={<Profile />} />
            <Route path="/bookings" element={<Bookings />} />
            <Route path="/cold-room" element={<ColdRoomForm />} /> */}
          </Routes>
        </div>
      </div>
    </div>
  );
};

export default AdminDashboard;
