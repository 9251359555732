import React from 'react';
import { useInView } from 'react-intersection-observer';

const AboutSection = () => {
  const { ref, inView } = useInView({ threshold: 0.2 });

  return (
    <div
      ref={ref}
      className={`bg-blue-50 py-12 transform transition-all duration-1000 ${inView ? 'opacity-100 translate-y-0' : 'opacity-0 translate-y-10'}`}
    >
      <div className="container mx-auto px-6 space-y-12">
        <div>
          <h2 className="text-green-600 text-3xl font-bold font-big-shoulders">About Us</h2>
          <p className="text-gray-700 mt-4 leading-relaxed font-noto-sans">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras gravida scelerisque felis eu fermentum. 
            Nam ac eleifend nulla. Proin elit ante, tristique at vulputate non, hendrerit sed orci. Aenean mattis, 
            orci ac feugiat tempus, urna mauris congue tortor, et pharetra diam massa sed nisi. Mauris fringilla 
            diam aliquet semper laoreet. Quisque tincidunt nisi faucibus purus ornare, quis lobortis urna sodales.
          </p>
        </div>

        <div>
          <h3 className="text-blue-600 text-2xl font-semibold font-big-shoulders">Our Mission</h3>
          <p className="text-gray-700 mt-4 leading-relaxed font-noto-sans">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras gravida scelerisque felis eu fermentum. 
            Nam ac eleifend nulla. Proin elit ante, tristique at vulputate non, hendrerit sed orci. Aenean mattis, 
            orci ac feugiat tempus, urna mauris congue tortor, et pharetra diam massa sed nisi.
          </p>
        </div>

        <div>
          <h3 className="text-blue-600 text-2xl font-semibold font-big-shoulders">Our Vision</h3>
          <p className="text-gray-700 mt-4 leading-relaxed font-noto-sans">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras gravida scelerisque felis eu fermentum. 
            Nam ac eleifend nulla. Proin elit ante, tristique at vulputate non, hendrerit sed orci. Aenean mattis, 
            orci ac feugiat tempus, urna mauris congue tortor, et pharetra diam massa sed nisi.
          </p>
        </div>
      </div>
    </div>
  );
};

export default AboutSection;
