const FeaturedEventSkeleton = () => {
    return (
      <section className="w-full animate-pulse">
        <div className="h-10 bg-gray-300 rounded-md mb-4 w-1/2"></div>
        <div className="h-40 bg-gray-300 rounded-md mb-6"></div>
        <div className="h-6 bg-gray-300 rounded-md w-4/6"></div>
        <div className="h-4 bg-gray-300 rounded-md mt-4 w-3/4"></div>
      </section>
    );
  };
  
  export default FeaturedEventSkeleton;
  