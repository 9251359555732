import React, { useState, useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import { createIntervention, updateIntervention } from "../../../redux/interventionSlice";
import { toast } from "react-toastify";
import "quill/dist/quill.snow.css";
import Quill from "quill";

const PostInterventionForm = ({ editMode = false, existingIntervention = {}, onClose }) => {
    const dispatch = useDispatch();
    const quillRef = useRef(null);
    const [quillInstance, setQuillInstance] = useState(null);

  const [form, setForm] = useState({
    title: editMode ? existingIntervention.title || "" : "",
    description: editMode ? existingIntervention.description || "" : "",
    image: null,
    imageUrl: editMode ? existingIntervention.imageUrl || "" : "",
  });

  // Initialize Quill editor
  useEffect(() => {
    if (!quillInstance) {
      const quill = new Quill("#quill-editor", {
        theme: "snow",
        placeholder: "Enter event description...",
        modules: {
          toolbar: [
            [{ header: [1, 2, false] }],
            ["bold", "italic", "underline", "strike"],
            [{ list: "ordered" }, { list: "bullet" }],
            [{ align: [] }],
            ["link"],
            ["clean"],
          ],
        },
      });

      // Update description in form state on content change
      quill.on("text-change", () => {
        setForm((prevForm) => ({
          ...prevForm,
          description: quill.root.innerHTML,
        }));
      });

      setQuillInstance(quill);
    }
  }, [quillInstance]);

  // Populate Quill editor in edit mode
  useEffect(() => {
    if (quillInstance && editMode && existingIntervention.description) {
      quillInstance.root.innerHTML = existingIntervention.description;
    }
  }, [editMode, existingIntervention, quillInstance]);
  

  // Handle input changes for title and image
  const handleChange = (e) => {
    const { name, value, files } = e.target;

    setForm((prevForm) => ({
      ...prevForm,
      [name]: files ? files[0] : value,
    }));
  };

  const handleSubmit = (e) => {
  e.preventDefault();

  if (!form.title.trim() || !form.description.trim()) {
    toast.error("Title and description are required.");
    return;
  }

  const formData = new FormData();
  formData.append("title", form.title);
  formData.append("description", form.description);

  if (form.image) {
    formData.append("image", form.image);
  } else if (editMode) {
    formData.append("imageUrl", form.imageUrl);
  }

  if (editMode) {
    dispatch(updateIntervention({ id: existingIntervention.id, interventionData: formData }))
      .unwrap()
      .then(() => {
        toast.success("Intervention updated successfully!");
        onClose && onClose();
      })
      .catch(() => toast.error("Failed to update intervention"));
  } else {
    dispatch(createIntervention(formData))
      .unwrap()
      .then(() => {
        toast.success("Intervention created successfully!");
        setForm({
          title: "",
          description: "",
          image: null,
          imageUrl: "",
        });
        if (quillInstance) quillInstance.root.innerHTML = "";
      })
      .catch(() => toast.error("Failed to create intervention"));
  }
};


  return (
    <form className="space-y-4" onSubmit={handleSubmit}>
      {/* Title Input */}
      <input
        type="text"
        name="title"
        placeholder="Event Title"
        value={form.title}
        onChange={handleChange}
        className="w-full p-3 border rounded-md"
      />

      {/* Description Quill Editor */}
      <div id="quill-editor" className="w-full h-40 border rounded-md"></div>

      {/* Image Upload */}
      <input
        type="file"
        name="image"
        accept="image/*"
        onChange={handleChange}
        className="w-full p-3 border rounded-md"
      />
      {editMode && form.imageUrl && (
        <div className="flex items-center space-x-4">
          <img
            src={form.imageUrl}
            alt="Current"
            className="w-16 h-16 object-cover rounded-md"
          />
          <span className="text-sm text-gray-500">Current Image</span>
        </div>
      )}

      {/* Submit Button */}
      <button
        type="submit"
        className="bg-blue-500 text-white py-2 px-4 rounded"
      >
        {editMode ? "Update Event" : "Create Event"}
      </button>
    </form>
  );
};

export default PostInterventionForm;
